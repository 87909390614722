<template>
  <div class="rebalance-window fixed-plugin" :class="parentClass">

    <div class="shadow-lg card blur" style="overflow-y: scroll; overflow-x: hidden !important;">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Ребалансировка портфеля</h5>
        </div>
        <div class=" float-end" @click="closeWindow()">
          <button class="p-0 btn btn-link text-dark fixed-plugin-close-button">
          <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
      <div class="table-responsive mt-3" style="width: 100%;overflow-x: clip;">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Токен</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Цена</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Количество</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Баланс</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Процент</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 w-20"></th>
          </tr>
          </thead>
          <tbody v-if="crypto_tokens_all && Object.keys(crypto_tokens).length > 0">
            <tr v-for="current_token in crypto_tokens.filter(item => item.amount >= 0)" v-bind:key="current_token.id">
              <td class="align-middle text-center text-sm">
                <div class="d-flex align-items-center ps-2">
                  <div class="ps-3 d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm text-uppercase">{{ current_token.ticket }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="text-xs font-weight-bold">{{ new Intl.NumberFormat('en-US').format(current_token.currentPrice) }}</span>
                  <soft-avatar :img="tetherImg" class="ms-2 d-flex align-items-center soft_avatar--tehter" alt="xd" />
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="text-xs font-weight-bold">{{ new Intl.NumberFormat('en-US', { maximumFractionDigits: 7 }).format(current_token.balance) }}</span>
                <!-- <span class="text-xs font-weight-bold">{{ current_token.balance.toString().split('.')[1].length > 5 ? current_token.balance : new Intl.NumberFormat('en-US').format(current_token.balance) }}</span> -->
              </td>
              <td class="align-middle text-center text-sm">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="text-xs font-weight-bold">{{  parseFloat(current_token.amount.toFixed(2)).toLocaleString('ru-RU') }}</span>
                  <soft-avatar :img="tetherImg" class="ms-2 d-flex align-items-center soft_avatar--tehter" alt="xd" />
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="text-muted ">
                  <input type="number" :min="0" :max="100" id="`customFielde${current_token.id}`" @change="customPercent($event, current_token)" class="badge rounded-pill bg-dark" style="width: 60px; height: 100%;"
                  :value="parseInt(current_token.percent)" />
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="w-100 d-flex align-items-center justify-content-center">
                  <div class="me-3 d-flex align-items-center">
                    <input style="width: 200px" type="range" :min="0" :max="100" class="form-range slide" id="`customRange${current_token.id}`" @change="customPercent($event, current_token)" :value="current_token.percent">
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pt-0 pb-2 card-body mt-3">
        <!-- <div class="my-3 d-flex flex-column w-100">
          <h6 class="mb-0">Добавьте монету в портфель:</h6>
          <div class="d-flex flex-row w-100">
            <select class="form-select" v-model="selectedToken" @change="tokenSelect($event)">
              <option value="selected" selected>Выберите монету для добавления в портфель</option>
              <template v-for="item in crypto_tokens_all" v-bind:key="item.ticket">
                <option v-if="item.checked == false" :value="item.ticket">{{item.ticket}}{{item.in_portfolio == true && item.amount != 0 ? "  -  (текущая сумма на балансе " + item.amount.toLocaleString('ru') + " $)" : ""}}</option>
              </template>
            </select>
          </div>
        </div> -->

        <soft-alert color='light'><i class="fa fa-chart-pie opacity-6 me-1" aria-hidden="true" style='color:#000'></i>
          <small style='color:#000'>Ребаланс произойдет только с монетами, где сумма изменений была более 10$</small> <br>
        </soft-alert >

        <div class="w-100 soft-notification" :class="[this.notificationError == true ? 'border-red' : '']">
          <soft-checkbox v-model="notificationRebalance" @change="notificationRebalance = !notificationRebalance">
            <p class="">Нажимая, я соглашаюсь, что перед ребалансировкой я снял все ордера для освобождения средств и принимаю все риски.</p>
            <!-- <a target="_blank_" href="/instruction">Инструкция</a> -->
          </soft-checkbox>
        </div>

        <hr class="horizontal dark my-sm-2" />

        <a
          class="btn bg-gradient-dark w-100"
          @click="setRebalance()"
          >Произвести ребаланс</a
        >

      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import tetherImg from "@/assets/img/tether-usdt-logo.svg";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
// import SoftRadio from "@/components/SoftRadio.vue";
// import SoftInput from "@/components/SoftInput.vue";
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  name: "addRebalance",
  props: ["toggle", "parentClass"],
  data() {
    return {
      tetherImg,
      crypto_tokens_all: null,
      // [
        // {id: 0, ticket: 'BTC', currentPrice: 15000, balance: 0.0005, amount: 0, percent: 0, in_portfolio: true, checked:true},
        // {id: 1, ticket: 'DOGE', currentPrice: 0.085, balance: 5, amount: 0, percent: 0, in_portfolio: true, checked:false},
        // {id: 2, ticket: 'BUSD', currentPrice: 1, balance: 1, amount: 0, percent: 0, in_portfolio: true, checked:true},
        // {id: 4, ticket: 'DOT', currentPrice: 12, balance: 0, amount: 0, percent: 0, in_portfolio: false, checked:true},
      // ],
      accountAmount: 0,
      selectedToken: 'selected',
      notificationRebalance: false,
      notificationError: false,
    };
  },
  components: {
    SoftAvatar,
    SoftAlert,
    SoftCheckbox
    // SoftRadio,
    // SoftInput
  },
  computed: {
    ...mapState(["tsl", "rebalancePortfolio", "userPlot", "activePortfolioSelect"]),
    min(){
      return 1
    },
    max(){
      return this.accountAmount
    },
    freePercent() {
      return 100 - this.getFullPercent()
    },
    crypto_tokens() {
      return (this.crypto_tokens_all ? this.crypto_tokens_all.filter(item => item.checked === true) : [])
    }
  },
  watch: {
    rebalancePortfolio: {
      immediate: true,
      handler(newTokens) {
        // console.log(newTokens)
        this.crypto_tokens_all = newTokens
        // console.log('was getted')
        this.startMath()
        // console.log(this.crypto_tokens_all)
      },
    },
  },
  created() {
  },
  mounted() {
    this.startMath();
    this.notificationRebalance = false;
    this.notificationError = false;
  },
  methods: {
    ...mapActions(["setRebalancePortfolio"]),
    ...mapMutations([]),
    closeWindow() {
      this.notificationRebalance = false;
      this.notificationError = false;
      this.toggle();
    },
    startMath() {
      // Расчитали сумму баланса в USDT и установили суммы
      this.accountAmount = this.getAccountAmount()
      // Посчитали проценты изначальные
      this.getAccountPercents()
    },
    getAccountAmount() {
      let full_amount = 0
      this.crypto_tokens.forEach(element => {
        let newAmount = element.currentPrice * element.balance
        element.amount = newAmount
        full_amount += newAmount
        console.log(newAmount)
      })
      return full_amount
    },
    getAccountPercents() {
      this.crypto_tokens.forEach(element => {
        let newPercent = (element.amount / this.accountAmount) * 100
        element.percent = Math.floor(newPercent)
        console.log(newPercent)
      })
    },
    getFullPercent() {
      return this.crypto_tokens.reduce((total, token) => total + token.percent, 0)
    },
    cutOff(currentToken, stayPercentage) {
      console.log('stayPercentage : ', stayPercentage)
      if (stayPercentage > 0) {
        let maxToken = this.getMax(currentToken)
        if (maxToken.percent > stayPercentage) {
          maxToken.percent -= stayPercentage
        } else {
          stayPercentage -= maxToken.percent
          console.log('Minus : ', maxToken.percent, ' = ', stayPercentage) 
          maxToken.percent = 0
          this.cutOff(currentToken, stayPercentage)
        }
      console.log('getFullPercent : ', this.getFullPercent())
      }
    },
    getMax(token) {
      var max;
      let arr = this.crypto_tokens_all;
      for (var i=0 ; i<arr.length ; i++) {
          if (max == null || (parseInt(arr[i]['percent']) > parseInt(max['percent'])) && arr[i] != token ) max = arr[i];
      }
      return max;
    },
    customPercent(event, token) {
      const oldValue = token.percent
      const newValue = parseInt(event.target.value)
      console.log(this.freePercent)
      token.percent = newValue
      // if (newValue == 0) {
      //   // token.checked = false
      //   token.balance = 0
      //   token.percent = 0
      //   token.amount = 0
      // } else if (this.freePercent >= 0 && newValue <= 100 - this.freePercent) {
      //   token.percent = newValue
      //   this.updateTokensPercent()
      // } else {
      if (newValue > oldValue) {
        this.cutOff(token, newValue - oldValue)
      } else if (newValue < oldValue) {
        let maxToken = this.getMax(token)
        maxToken.percent += oldValue - newValue
      }
      token.percent = newValue

      let maxToken = this.getMax(token)
      maxToken.percent += this.freePercent
      this.updateTokensPercent()
    },
    updateTokensPercent() {
      this.crypto_tokens.forEach((element) => {
        let newAmount = element.percent * this.accountAmount / 100
        element.amount = newAmount
        element.balance = newAmount / element.currentPrice
      })
    },
    sidebarColor(color = "warning") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },
    rebalance(){
      this.notificationRebalance = false;
      this.notificationError = false;
      this.toggle();
    },
    tokenSelect(event) {
      let new_token = event.target.value;
      this.crypto_tokens_all.forEach(element => {
        if (element.ticket === new_token) {
          element.balance = 0
          element.amount = 0
          element.percent = 0
          element.checked = true
        } 
        this.selectedToken = 'selected';
      })
    },
    setRebalance() {
      let original_portfolio = {}
      this.rebalancePortfolio.forEach((item) => {
        original_portfolio[item['ticket']] = item
      })

      let end_portfolio = {}
      this.crypto_tokens_all.forEach((item) => {
        end_portfolio[item['ticket']] = item
      })

      var response_data = {
        account_id: this.tsl.portfolio[this.activePortfolioSelect].id,
        original_portfolio: original_portfolio,
        end_portfolio: end_portfolio
      }

      console.log(response_data)

      this.notificationRebalance = false;
      this.notificationError = false;

      this.setRebalancePortfolio({vm: this, data: response_data});

      this.toggle()
    }
  },
};
</script>


<style scoped>
  .rebalance-window.fixed-plugin {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  padding-left: 15.625rem;
  /*    transform: translateX(-50%);*/
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  }

  .rebalance-window.fixed-plugin.show {
  display: flex;
  }

  .rebalance-window.fixed-plugin .card {
  position: relative !important;
  width: 70%;
  height: fit-content;
  max-height: 80%;
  overflow-y: auto !important;
  z-index: 10;
  background: #FFFFFF;
  border-radius: 15px;
  }

  .sl--button {
    cursor: pointer;
  }

  .sl--button.dark {
    background: black;
    color: #FFFFFF;
  }

  .sl--button.light {
    background: #FFFFFF;
    color: black;
    border: 1px solid black;
  }

  .soft_avatar--tehter {
    width: 17px;
    height: 17px;
  }

  .time-radio {
    width: 30px;
    height: 30px;
    background: #BFBFBF;
    border-radius: 8px;
    border: 0;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-radio p {
    padding: 0;
    margin: 0;
  }

  .time-radio.checked-radio {
    background: black;
  }


  .text-color--green {
    color: rgb(25,135,84) !important;
  }

  .my-style-rounded{
    border-radius: 50% ;
    border: 10px solid #F2F2F2 !important; 
    aspect-ratio: 1 / 1 !important;
    width:100%; 
  }

  .slide{
      width: 100%;
      height: 4px;
      background: #AED4FB ;
  }
  .slide::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%; 
    background: #000000  !important;
    cursor: pointer;
  }

  .slide::-moz-range-thumb {
    border-radius: 50%;
    background: #000000 !important;
    cursor: pointer;
  }
</style>