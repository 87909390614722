<template>
  <div class="card-body px-0 pb-2">
    <div class="table-responsive mt-3">
      <div class="col-lg-6 col-7 ps-4">
        <h6 class="font-weight-bold">Транзакции</h6>
      </div>
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-3">Торговая пара</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Дата транзакции</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ОбЪём</th>
            <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Итоги операции</th> -->
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Биржа</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Тип операции</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading.account_status == 'master'">Дочерние сделки</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transaction in transactionsData" v-bind:key="transaction.id">
            <td>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <h6 class="mb-0 text-sm text-uppercase">{{ transaction.ticket }}</h6>
                <h6 class="mb-0 text-sm fw-light">{{ transaction.name }}</h6>
              </div>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="text-m font-weight-bold">{{ getNormalDate(transaction.date) }}</span>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="text-m font-weight-bold">{{ new Intl.NumberFormat('en-US').format(transaction.volume) }}</span>
            </td>
            <!-- <td class="align-middle text-center text-sm">
              <span :class="[ 'text-xs', 'font-weight-bold', transaction.profit > 0 ? 'text-color--green' : 'text-danger',]">{{ transaction.profit >= 0 ? "+ " : "- " }} {{ transaction.profit }}</span>
              <span class="text-xs font-weight-bold"> %</span>
            </td> -->
            <td class="align-middle text-center text-sm">
              <span class="text-m font-weight-bold">{{ transaction.exchange }}</span>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="text-m font-weight-bold">{{ transaction.operation_type }}</span>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="text-m font-weight-bold" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading.account_status == 'master'">{{ transaction.child_count }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<style scoped>
  
  .text-color--green {
    color: rgb(25,135,84) !important;
  }

</style>

<script>
import { mapMutations, mapActions,mapGetters } from "vuex";
import img1 from "../../assets/img/small-logos/logo-xd.svg";

export default {
  name: "transactions-card",
  props: [
    'transactionsData'
  ],
  data() {
    return {
      img1,
      transaction_types: {
        'trade': 'Торговля',
        'sl': 'Стоп-Лосс',
        'rebalance': 'Ребалансировка',
      },
    };
  },
  components: {
  },
  mounted() {
    // console.log("transactions: ", this.transactionsData)
  },
  computed: {
  ...mapGetters([]),
  },
  watch:{
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    getNormalDate(isoTime) {
      // let retDate = new Date(Date.parse(date)).toDateString()
      const date = new Date(isoTime);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const humanReadableTime = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
      return humanReadableTime
    },
  }
};
</script>
