<template>
  <div class="fixed-plugin " >
  
    <div class="shadow-lg card blur" style="overflow-y: scroll; overflow-x: hidden !important; ">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Подключить Binance</h5>
        </div>
        
        <div class="mt-3 float-end">
          <close @click="close_window"/>
        </div>
      </div>
      <div class="pt-0 card-body " style="min-height:150%">
        <div class="mt-3">
          <h6 class="mb-0">Тип торговли</h6>
          <p class="text-sm"></p>
        </div>
        <div class="row">
          <div 
            class='col-6'
            v-for="(item,index) in typeAccount"
            :key="item.slug"
          > 
              <input class="w-100 btn-check" type="radio" name="robot-own-pay"  v-on:change="robot = item;check_index = index" :id="`own-pay-${item.id}`" :value="item.slug" :disabled="false" :checked='index==0'>
              <label class="w-100 btn" :class="{'btn-dark':index === check_index,'btn-outline-dark':index != check_index}" :for="`own-pay-${item.id}`"> <small>{{item.name}}</small> </label>
          </div> 
        </div>
        <soft-alert color = 'info' >
          <i class="fa fa-chart-pie opacity-6 me-1 ms-0" aria-hidden="true" ></i>
          <b> {{robot.name}}</b>
          <br>
          <small>При создании API ключа, выберите соответсвующие права для совершения торговых действий <a href="https://www.binance.com/ru/support/faq/%D0%BA%D0%B0%D0%BA-%D1%81%D0%BE%D0%B7%D0%B4%D0%B0%D0%B2%D0%B0%D1%82%D1%8C-api-%D0%BA%D0%BB%D1%8E%D1%87%D0%B8-%D0%BD%D0%B0-binance-360002502072" target="_blank">подробнее</a>
          </small> 
        </soft-alert >

        <label>Короткое название</label>
        <div class="input-group mb-2">
          <input 
            id="name_account"
            type="text"
            v-model='name_account'
            placeholder="Название"
            name="name_account"
            @change="name_account=$event.target.value"
            class="form-control" 
            aria-label="">
        </div>

        <label>API KEY</label>
        <div class="input-group mb-2">
          <input 
            id="api_key"
            type="text"
            v-model='api_key'
            placeholder="Ключ API"
            name="api_key"
            @change="api_key=$event.target.value"
            class="form-control" 
            aria-label="">
        </div>

        <label>Secret KEY</label>
        <div class="input-group ">
        <input 
          id="secret_key"
          type="text"
          v-model='secret_key'
          placeholder="Secret Key"
          name="secret_key"
          @change="secret_key=$event.target.value"
          class="form-control" 
          aria-label="">
        </div>

        <!-- <label>подробнее как получить ключ по <a target="_blank" href="">
          <span class="badge badge-sm bg-gradient-dark" style="border-radius: 10px; width:auto; height:auto; font-size: 10px;">ссылке</span></a>
        </label> -->


        <hr class="horizontal dark my-sm-4" />
        <a
          class="btn bg-gradient-dark w-100"
          @click="add()"
          >Добваить биржу</a
        >
      </div>
    </div>
  </div>
</template>

<script>
// import SoftRadio from "@/components/SoftRadio.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import Close from "../components/Icon/Close.vue";

// import SoftInput from "@/components/SoftInput.vue";
import { mapMutations, mapActions,mapGetters } from "vuex";
export default {
  name: "addBinance",
  props: ["toggle"],
  data() {
    return {
      fixedKey: "",
      showPromoCode: false,
      promocode: '',
      check_index: 0,
      api_key: '',
      secret_key: '',
      name_account:'',
      min: 600,
      robot:{
        'slug': 'spot',
        'id': 2,
        'name': 'Спотовый',
        'detail_link':'',
        checked: true
      },
    };
  },
  components: {
    SoftAlert,
    Close,
  },
  methods: {
    ...mapMutations(["navbarMinimize", "sidebarType", "navbarFixed"]),
    ...mapActions(["toggleSidebarColor","getUserData", "AddAccount"]),

    close_window(){
      console.log('close window')
      this.$store.state.binanceAddWindow = false;
    },
    sidebarColor(color = "warning") {
      document.querySelector("#sidenav-main").setAttribute("data-color", color);
      this.$store.state.mcolor = `card-background-mask-${color}`;
    },

    sidebarType(type) {
      this.toggleSidebarColor(type);
    },
    toggle_promocode(){
      this.showPromoCode = !this.showPromoCode;
    },
    setNavbarFixed() {
      if (this.$route.name !== "Profile") {
        this.$store.state.isNavFixed = !this.$store.state.isNavFixed;
      }
    },
    add(){
      if (this.name_account.length < 2){
        this.$toast.error("Укажите несколько символов в названии", { });
        return 1
      }
      if (this.api_key.length < 10){
        this.$toast.error("Неккоректный API KEY", { });
        return 1
      }
      if (this.secret_key.length < 10){
        this.$toast.error("Неккоректный SECRET KEY", { });
        return 1
      }
      // if(this.price < 1000) alert('Введите корректную сумму пополнения')
      var data = {
        type_account: this.robot.slug,
        api_key: this.api_key,
        secret_key: this.secret_key,
        name_account: this.name_account,
        slug:'binance',
      }
      this.AddAccount({vm:this,data:data});
      this.close_window();
    },

  },
  mounted() {
    this.getUserData()
  },
  computed: {
     ...mapGetters(["user","userBots","typeAccount"]),
    ifTransparent() {
      return this.$store.state.isTransparent;
    },
    deposited(){
      if (this.user.deposits)
        return this.user.deposits[this.robot.slug] 
      else return 0
    },
    minLimit() {
      var limit = this.robot.minimum * 60  - this.deposited
      if (limit < 0) return 600
      return limit
    },

  },
  beforeMount() {
    this.$store.state.isTransparent = "bg-dark";
    // Deactivate sidenav type buttons on resize and small screens
    this.sidebarColor('warning')
  },
};
</script>
