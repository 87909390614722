import { createRouter, createWebHistory } from "vue-router";
// import Dashboard from "@/views/Dashboard.vue";
import Invest from "@/views/Invest.vue";
import Exchanges from "@/views/Exchanges.vue";
import CopyTrading from "@/views/CopyTrading.vue";
import Profile from "@/views/Profile.vue";
// import Rtl from "@/views/Rtl.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
// import Calc from "@/views/Calc.vue";
// import Muted from "@/views/Muted.vue";
import Restore from "@/views/Restore.vue";

const routes = [
  {
    path: "/",
    name: "/",
    // redirect: "/dashboard",
    redirect: "/exchanges",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    redirect: "/exchanges",
  },
  {
    path: "/exchanges",
    name: "Exchanges",
    component: Exchanges,
  },
  {
    path: "/invest",
    name: "Invest",
    // component: Muted,
    component: Invest,
  },
  {
    path: "/copy-trading",
    name: "CopyTrading",
    component: CopyTrading,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
  },
  // {
  //   path: "/rtl-page",
  //   name: "Rtl",
  //   component: Rtl,
  // },
  {
    path: "/sign-in",
    name: "Sign In",
    component: SignIn,
  },
  {
    path: "/sign-up",
    name: "Sign Up",
    component: SignUp,
  },
  // {
  //   path: "/sign-calculator",
  //   name: "Calc",
  //   component: Calc,
  // },
  {
    path: "/sign-restore",
    name: "Restore",
    component: Restore,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

export default router;
