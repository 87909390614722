<template>
  <sidenav
    :custom_class="this.$store.state.mcolor"
    :class="[
      this.$store.state.isTransparent,
      this.$store.state.isRTL ? 'fixed-end' : 'fixed-start',
    ]"
    v-if="this.$store.state.showSidenav"
  />
  <main
    class="mt-4 main-content position-relative max-height-vh-100 h-100 border-radius-lg"
    :style="this.$store.state.isRTL ? 'overflow-x: hidden' : ''"
  >
    <!-- nav -->
    <!-- <navbar
      :class="[navClasses]"
      :textWhite="this.$store.state.isAbsolute ? 'text-white opacity-8' : ''"
      :minNav="navbarMinimize"
      v-if="this.$store.state.showNavbar"
    /> -->

    <div class="loader" v-if='(this.authenticated && !user.email) || this.loader' >
      <loader />
    </div>
    <router-view v-else />

    <add-binancei
      :toggle="tooggleAddBinance"
      :class="[
        this.$store.state.binanceAddWindow ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />
    <add-huobi
      :toggle="tooggleAddHuobi"
      :class="[
        this.$store.state.huobiAddWindow ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />
    <add-tsl
      :toggle="tooggleAddTSL"
      :class="[
        this.$store.state.tslAddWindow ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />
    <add-copy-child
      :toggle="tooggleAddCopyChild"
      :class="[
        this.$store.state.copyChildAddWindow ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
        'add-copy-child'
      ]"
    />
      <!-- :toggle="this.$store.state.toggleRebalance" v-if="this.$store.state.rebalance" -->
    <add-rebalance
      :toggle="toggleRebalance"
      :class="[
        this.$store.state.rebalance ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
        'toggle-rebalance'
      ]"
    />
    <!-- <withdraw
      :toggle="toggleWithdraw"
      :class="[
        this.$store.state.withdraw ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    />
    <topup-crypto
      :toggle="toggleTopUpCrypto"
      :class="[
        this.$store.state.topUpCrypto ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    /> -->
    <!-- <topup
      :toggle="tooggleAddHuobi"
      :class="[
        this.$store.state.showConfig ? 'show' : '',
        this.$store.state.hideConfigButton ? 'd-none' : '',
      ]"
    /> -->


    <app-footer v-show="this.$store.state.showFooter" />
  </main>
</template>

<script>
import Sidenav from "./examples/Sidenav";
import loader from "./views/components/Loader.vue";
import AddBinancei from "@/examples/AddBinancei.vue";
import AddHuobi from "@/examples/AddHuobi.vue";
import AddCopyChild from "@/examples/AddCopyChild.vue";
import AddTsl from "@/examples/AddTsl.vue";
import AddRebalance from "@/examples/AddRebalance.vue";
// import Navbar from "@/examples/Navbars/Navbar.vue";
// import Topup from "@/examples/AddHuobi.vue";
import AppFooter from "@/examples/Footer.vue";
import { mapMutations, mapState, mapActions } from "vuex";
export default {
  name: "App",
  components: {
    Sidenav,
    // Topup,
    "add-rebalance": AddRebalance,
    // Navbar,
    AppFooter,
    loader,
    AddBinancei,
    AddHuobi,
    AddTsl,
    AddCopyChild,
  },
  methods: {
    ...mapActions(["getUserPlot", "auth_by_query", "getUserData"]),
    ...mapMutations([
      "tooggleAddHuobi",
      // "toggleWithdraw", 
      "navbarMinimize",
      "toggleTopUpCrypto",
      "tooggleAddBinance",
      "tooggleAddTSL",
      "tooggleAddCopyChild",
      "toggleRebalance",
      "toggleAllClose"
    ]),
    ...mapState(['loader']),
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  watch:{
      authenticated(val){
        console.log('authenticated',val)
        if (val){
          if (this.$router.currentRoute.value.path.includes('sign')) {
          this.getUserPlot()
          this.$router.push('/dashboard')
          }
        }
        else{
          if (!this.$router.currentRoute.value.path.includes('sign'))  {
            if(this.$route.query.token){
              this.auth_by_query({vm:this,token:this.$route.query.token});
            }
            else{
              this.$router.push('/sign-in')
              this.$toast.info("Авторизуйтесь в личном кабинете", { });
            }
          }
        }
      },
      $route (to, from){
        if (!(to.path.includes('sign')) && !this.authenticated){
          if(this.$route.query.token){
              this.auth_by_query({vm:this,token:this.$route.query.token});
            }
          else{
            this.$toast.info("Авторизуйтесь в личном кабинете", { });
            this.$router.push('/sign-in')
          }
        }
        if (to.path!=from.path && this.isMobile()){
          this.navbarMinimize()
        }
        this.toggleAllClose();
    }
  },
  computed: {
    ...mapState(['authenticated','user', 'loader']),
    navClasses() {
      return {
        "position-sticky blur shadow-blur mt-4 left-auto top-1 z-index-sticky": this
          .$store.state.isNavFixed,
        "position-absolute px-4 mx-0 w-100 z-index-2": this.$store.state
          .isAbsolute,
        "px-0 mx-4": !this.$store.state.isAbsolute,
      };
    },
    showBottomBar() {
      if (window.matchMedia('(display-mode: standalone)').matches) {  
          // do things here  
          // set a variable to be used when calling something  
          // e.g. call Google Analytics to track standalone use   
          return true;
      }  

      return false;
    },
  },
  beforeMount() {
    // this.toggleAllClose();
    // console.log(this.$store.state.showNavbar);
    // this.$store.state.isTransparent = "bg-transparent";
    if (localStorage.getItem('token')){
        if (localStorage.getItem('token').length > 5) this.$store.state.authenticated = true
    }
    else{
      if(this.$route.query.token){
        this.auth_by_query({vm:this,token:this.$route.query.token});
      }
    }
  },
  created() {
    window.setInterval(() => {
      console.log('Interval')
      this.getUserPlot();
      this.getUserData();
    }, 60000*5);
  },
};
</script>


<style>
  .loader{
    z-index:9999;
    top:0;
    left:0;
    text-align: center;
    position: fixed;
    background: linear-gradient(0deg, #1E1E1E, #1E1E1E), radial-gradient(112.49% 34.48% at 46.93% 30.17%, rgba(170, 168, 168, 0.2) 0%, rgba(0, 0, 0, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    height: 100vh;
    width: 100vw;
    display: flex;
    height:100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .btn{
    min-height: 40px;
  }
</style>