<template>
  <div
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100  text-white"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
     <!--  <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'dashboard' ? 'text-dark' : 'text-white'"
          navText="Главная" :to="{ name: 'Dashboard' }">
          <template #icon>
            <shop />
          </template>
        </sidenav-collapse>
      </li> -->
      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'exchanges' ? 'text-dark' : 'text-white'"
          navText="Биржи" :to="{ name: 'Exchanges' }">
          <template #icon>
            <office />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'invest' ? 'text-dark' : 'text-white'"
          navText="Портфели" :to="{ name: 'Invest' }">
          <template #icon>
            <credit-card/>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'copy-trading' ? 'text-dark' : 'text-white'"
          navText="Копи Трейдинг" :to="{ name: 'CopyTrading' }">
          <template #icon>
            <document />
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse 
          :class="getRoute() == 'profile' ? 'text-dark' : 'text-white'"
          navText="Профиль" :to="{ name: 'Profile' }">
          <template #icon>
            <customer-support />
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
// import Shop from "../../components/Icon/Shop.vue";
import Office from "../../components/Icon/Office.vue";
import Document from "../../components/Icon/Document.vue";
import CreditCard from "../../components/Icon/CreditCard.vue";
import CustomerSupport from "../../components/Icon/CustomerSupport.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Amplidyne",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  components: {
    SidenavCollapse,
    // Shop,
    Office,
    CreditCard,
    CustomerSupport,
    Document,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
