<template>
  <div class="py-4 container-fluid">
    <div class="card">

      <div class="card-header pb-0">
        <div class="row">
          <div class="col-lg-6 col-7">
            <h6>Копи Трейдинг</h6> 
          </div>
        </div>
      </div>

      <!-- <p>plot {{this.$store.state.userPlot ? Object.keys(userPlot).length : 10}}</p> -->

      <div class="w-100 d-flex flex-column align-items-start" v-if="userPlot && Object.keys(userPlot).length > 0 ">
        <div class="w-100 d-flex flex-row mx-3 justify-content-between align-items-start">
          <div class="mt-1 d-flex flex-column col-lg-3">
            <span class="ms-1 text-sm">Выберите портфель</span>
            <select class="form-select mb-2 mt-1" v-model="activePortfolio" @change="changeActiveSelect()">
              <option  v-bind:key="key" v-for="(portfolio, key) in userPlot" :value="key" >
                {{ portfolio.full_name }}
              </option>
            </select>
          </div>

          <div class="col-lg-4 p-3 mt-2 mx-3 mb-5 d-flex flex-row justify-content-end align-items-end" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading && userPlot[activePortfolio].copytrading.account_status == 'master'" >
            <button @click="CopyMasterCode()" target="_blank" class="btn btn-dark w-100">Скопировать код для подключения дочернего аккаунта</button>
          </div>

          <div class="col-lg-4 p-3 mt-2 mx-3 mb-2 d-flex flex-row justify-content-end align-items-end" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading && userPlot[activePortfolio].copytrading.account_status == 'connected'" >
            <button @click="changeCopyChild('deleting')" target="_blank" class="btn btn-dark w-100">Отключить этот аккаунт от CopyTrading</button>
          </div>

        </div>
      </div>

      <div class="d-flex justify-content-center align-items-center mt-3" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading.account_status == 'notconnected'">
        <div class="card d-flex justify-content-center align-items-center w-35 p-3 mb-2 border">
          <h6 class="font-weight-bold">Статус : {{ userPlot[activePortfolio].copytrading.account_status == 'connected' ? 'Подключено' : 'Не подключено' }}</h6>
        </div>
        <div class="card d-flex justify-content-center align-items-center w-35 p-3 mb-2 border mx-3" v-if="userPlot[activePortfolio].copytrading.account_status == 'connected' && this.userPlot[this.activePortfolio].copytrading.transactions.length > 0">
          <h6 class="font-weight-bold">{{ getInterval() }}</h6>
        </div>
      </div>

      <div class="pt-3 mt-3 mb-5 w-100 d-flex flex-row justify-content-between" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading.account_status == 'notconnected'" >
        <div id="sidenavCard" class="shadow-none card card-background card-background-mask-light w-100 ms-3 me-3">
          <div class="full-background" :style="{ 'background-image': 'url(' + imgWhiteCurved + ')' }" ></div>
          <div class="p-3 card-body text-start w-100">
            <div class="mb-3 text-center bg-white shadow icon icon-shape icon-sm d-flex align-items-center justify-content-center border-radius-md">
              <i
                id="sidenavCardIcon"
                class="top-0 text-lg text-dark text-gradient ni ni-support-16"
                aria-hidden="true"
              ></i>
            </div>
            <div class="docs-info">
              <h6 class="mb-0 text-dark up">Подключение к мастер аккаунту</h6>
              <p class="text-xs text-dark  font-weight-bold">Подключение к мастер аккаунту для доверительного управления средств</p>
              <button @click="connectNewAccount()" class="mb-0 btn btn-white w-100">Подключить</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row mx-2" v-if="userPlot && Object.keys(userPlot).length > 0 && (userPlot[activePortfolio].copytrading.account_status == 'connected' || userPlot[activePortfolio].copytrading.account_status == 'master')">
        <users-card v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading.account_status == 'master'" :mastercopyId="userPlot[activePortfolio].mastercopy_id" :usersData="userPlot[activePortfolio].copytrading.connected_accounts" />
        <transactions-card v-if="userPlot && Object.keys(userPlot).length > 0" :transactionsData="userPlot[activePortfolio].copytrading.transactions" />
      </div>

    </div>
  </div>
</template>

<script>
// import setTooltip from "@/assets/js/tooltip.js";
import UsersCard from "./components/UsersCard.vue";
import TransactionsCard from "./components/TransactionsCard.vue";
// import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import imgWhiteCurved from "@/assets/img/curved-images/white-curved.jpeg";
import copy from 'copy-to-clipboard';
import { mapMutations,mapActions, mapState } from "vuex"; // mapGetters,
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "copytrading",
  data() {
    return {
      iconBackground: "bg-gradient-info",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      imgWhiteCurved,
      activePortfolio: 0,
    };
    
  },
  components: {
    UsersCard,
    TransactionsCard,
    // MiniStatisticsCard,
  },
  computed:{
     //...mapGetters([ "activePortfolioSelect"]),"userPlot",
    ...mapState(["userPlot", "activePortfolioSelect"]),
  },
  created() {
    this.getUserPlot();
    this.activePortfolio = this.activePortfolioSelect
  },
  unMounted() {
  },
  mounted() {
    // setTooltip();
    // alert(this.userPlot)
  },
  methods: {
    ...mapMutations(["tooggleAddCopyChild"]),
    ...mapActions(["getUserPlot", "changeCopyAccount"]),
    changeActiveSelect() {
      this.$store.state.activePortfolioSelect = this.activePortfolio;
      this.$store.state.tslAddWindow = false
      this.$store.state.copyChildAddWindow = false
    },
    CopyMasterCode() {
      var copyText = this.userPlot[this.activePortfolio].copytrading.masterCode;
      copy(copyText);
      this.$toast.success("Скопировано!", {});
    },
    connectNewAccount() {
      console.log('new acc')
      this.tooggleAddCopyChild()
    },
    changeCopyChild(type){
      var response_data = {
        "type": type,
        "account_id": this.userPlot[this.activePortfolioSelect].account_id,
      }
      console.log("Response data: ", typeof(response_data), response_data)
      this.changeCopyAccount({vm:this, data:response_data});
    },
    getInterval() {
      if (this.userPlot[this.activePortfolio].copytrading.transactions.length > 0) {
        var d1 = Date.now()
        let last_transaction = this.userPlot[this.activePortfolio].copytrading.transactions[Object.keys(this.userPlot[this.activePortfolio].copytrading.transactions).length - 1];
        var d2 = new Date(last_transaction['date'])
        var interval = new Date(d1 - d2);
        let text = "";
        if (interval.getUTCDate() - 1 > 0 || interval.getUTCHours() > 0) text = "Более 1 часа назад";
        else text = String(interval.getUTCMinutes()) + (interval.getUTCMinutes() == (1 || 2 || 3 || 4) ? " минуты" : " минут") + " назад";
        text += " : Сделка " + last_transaction["ticket"]
        console.log(text)
        return text
      }
      
    }
  },
};
</script>
