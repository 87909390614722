<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="row">
        <div class="col-md-8 d-flex align-items-center">
          <h6 class="mb-0">{{ title }}</h6>
        </div>
        <div class="col-md-4 text-end">
          <!-- <a :href="action.route">
            <i
              class="text-sm fas fa-user-edit text-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              :title="action.tooltip"
            ></i>
          </a> -->
        </div>
      </div>
    </div>
    <div class="p-3 card-body">
      <!-- <p class="text-sm">
        {{ description }}
      </p>
      <hr class="my-4 horizontal gray-light" /> -->
      <ul class="list-group">

        <li class="text-sm border-0 list-group-item ps-0">
          <span class="mb-2 pb-2 ps-1">Ваш платежный аккаунт, неизменное поле</span> 
          <div class="input-group mb-2" >
            <input 
              id="email"
              type="text"
              :value="info.email"
              placeholder="например, info@invest.ru"
              disabled
              name="email"
              class="form-control" 
              aria-label="Ваш email">
          </div>
        </li>
        <li class="pt-0 text-sm border-0 list-group-item ps-0">
          
          <div class="input-group mb-0" >
              <span class="input-group-text"><strong class="text-dark">Имя и фамилия </strong> &nbsp;</span>
              <input 
                id="first_name"
                type="text"
                :value="info.fullName"
                @change="user_data.first_name = $event.target.value"
                placeholder="Имя и Фамилия"
                name="first_name"
                class="form-control" 
                aria-label="Ваше имя и фамилия">
          </div>

        </li>

        <li class="text-sm border-0 list-group-item ps-0">
          <div class="input-group mb-0 " >
            <span class="input-group-text"><strong class="text-dark">Телефон </strong> &nbsp;</span>
            <input 
              id="phone"
              type="text"
              :value="info.mobile"
              @change="user_data.phone = $event.target.value"
              placeholder="например, 79991112233"
              name="phone"
              class="form-control" 
              aria-label="Ваш номер телефона для связи">
          </div>
        </li>
        <li class="text-sm border-0 list-group-item ps-0">
          <hr class="horizontal dark my-sm-4" />
          <a
            class="btn bg-gradient-dark w-100"
            @click="save"
            >Cохранить</a
          >
        </li>


        
        <!-- <li class="text-sm border-0 list-group-item ps-0">
          <strong class="text-dark">Location:</strong> &nbsp;
          {{ info.location }}
        </li> -->
        <!-- <li class="pb-0 border-0 list-group-item ps-0">
          <strong class="text-sm text-dark">Social:</strong> &nbsp;
          <a
            v-for="({ icon, link }, index) of social"
            :key="index"
            class="py-0 mb-0 btn-simple ps-1 pe-2"
            :href="link"
          >
            <font-awesome-icon :icon="icon" />
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {mapActions } from "vuex";

export default {
  name: "ProfileInfoCard",
  data() {
    return {
      user_data:{}
    }
  },
  components: {
    // FontAwesomeIcon,
  },
  methods: {
    ...mapActions(["updateUser"]),
    save(){
         this.updateUser({vm:this,data:this.user_data})
    }
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      fullName: String,
      mobile: String,
      email: String,
      location: String,
      default: () => {},
    },
    social: {
      type: Array,
      link: String,
      icon: String,
      default: () => [],
    },
    action: {
      type: Object,
      route: String,
      tooltip: String,
      default: () => ({
        route: "javascript:;",
      }),
    },
  },
};
</script>
