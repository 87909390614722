/**
=========================================================
* Vue Soft UI Dashboard - v3.0.0
=========================================================

* Product Page: https://creative-tim.com/product/vue-soft-ui-dashboard
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import devtools from '@vue/devtools';
import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";
import Toaster from "@meforma/vue-toaster";
import VueClipboard from 'vue3-clipboard'
// import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
// import './registerServiceWorker';

const appInstance = createApp(App);
appInstance.config.devtools = true;

// if (process.env.NODE_ENV === 'development') {
//   devtools.connect('http://http://192.168.1.81:8080')
// }

// Sentry.init({
//   appInstance,
//   // dsn: "https://8385d68928f3463fad4a60e298661863@o562910.ingest.sentry.io/6610178",
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost:8080","localhost", "jstck.ru", /^\//],
//     }),
//   ],
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

appInstance.use(store);
appInstance.use(router);
appInstance.use(VueClipboard);
appInstance.use(SoftUIDashboard);
appInstance.use(Toaster,{
    // One of the options
    position: "bottom-left",
  });

// Dismiss all opened toast immediately
appInstance.mount("#app");
