<template>
  <div class="fixed-plugin ">
  
    <div class="shadow-lg card blur " style="overflow-y: scroll; overflow-x: hidden !important; " v-if="toggleCopyRebalance == false">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="mt-3 float-end">
          <close @click="close_window()"/>
        </div>
      </div>
      <div class="p-3 pb-0 pt-4 mt-3" style="background: #F8F9FA; border-radius: 12px;">
        <h5>Подключение к Мастер аккаунту</h5>
        <div class="pt-0 mt-4 w-100">
          <label>Код подключения, полученный от мастера</label>
          <div class="me-3 d-flex align-items-center w-100"> 
            <input type="text" v-model="masterCode" id="masterCodeInput" class="w-100 p-1" style="border-radius: 10px;" autocomplete="true">
          </div>
        </div>
        <div class="w-100 soft-notification" :class="[this.notificationError == true ? 'border-red' : '']">
          <soft-checkbox :checked="notificationRebalance" @change="notificationRebalance = !notificationRebalance">
            <p class="">Нажимая, я соглашаюсь, что перед ребалансировкой я снял все ордера для освобождения средств и принимаю все риски при открытых ордерах.</p>
          </soft-checkbox>
        </div>
        <!-- <hr class="horizontal dark" /> -->
      </div>
      <a class="mt-3 btn bg-gradient-dark w-100" @click="checkMasterPortfolio()">Узнать о портфеле мастер аккаунта</a>
    </div>



    <div class="copy-rebalance shadow-lg card blur" style="overflow-y: scroll; overflow-x: hidden !important;" v-if="toggleCopyRebalance == true && Object.keys(this.masterPortfolio).length > 0">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="mt-3 float-end">
          <close @click="close_window()"/>
        </div>
      </div>
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="float-start">
          <h5 class="mt-3 mb-0">Подключение к Мастер аккаунту. Подтверждение ребаланса портфеля.</h5>
        </div>
      </div>
      <div class="table-responsive mt-3" style="width: 100%;overflow-x: clip;">
        <table class="table align-items-center mb-0">
          <thead>
          <tr>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Токен</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Процент</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="current_token in crypto_tokens" v-bind:key="current_token.id" >
              <td class="align-middle text-center text-sm">
                <div class="d-flex align-items-center ps-2">
                  <div class="ps-3 d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm text-uppercase">{{ current_token.ticket }}</h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="text-xs font-weight-bold">{{ new Intl.NumberFormat('en-US').format(current_token.percent) }} %</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pt-0 pb-2 card-body mt-3">
        <soft-alert color='light'><i class="fa fa-chart-pie opacity-6 me-1" aria-hidden="true" style='color:#000'></i>
          <small style='color:#000'>На вашем аккаунте будут совершены сделки, чтобы соответсвовать Мастер аккаунту.</small> <br>
        </soft-alert >

        <hr class="horizontal dark my-sm-2" />

        <a
          class="btn bg-gradient-dark w-100"
           @click="addCopyChildAccount()"
          >Произвести ребаланс и подключиться к мастер аккаунту</a
        >

      </div>
    </div>



  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import axios from 'axios';
import Close from "../components/Icon/Close.vue";
import SoftAlert from "@/components/SoftAlert.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import img1 from "../assets/img/small-logos/logo-xd.svg";
import tetherImg from "@/assets/img/tether-usdt-logo.svg";

export default {
  name: "copyChild",
  props: ["toggle"],
  data() {
    return {
      img1,
      tetherImg,
      crypto_tokens_all: null,
      accountAmount: 0,
      toggleCopyRebalance: false,
      notificationRebalance: false,
      notificationError: false,
      masterCode: '',
      // BORZ2BINANCE232SPOT
      masterPortfolio: {},
    };
  },
  components: {
    Close,
    SoftAlert,
    SoftCheckbox
  },
  computed: {
    ...mapGetters(["userPlot", "activePortfolioSelect"]),
    ...mapState(['tsl']),
    crypto_tokens() {
      // return (this.masterPortfolio ? this.masterPortfolio.map(item => item.amount > 10) : {})
      return this.masterPortfolio
    }
  },
  mounted() {
    console.log('mounted AddCopyChild')
    this.close_window();
  },
  methods: {
    ...mapActions(["changeCopyAccount", "getMasterRebalancePortfolio"]),

    close_window() {
      this.masterPortfolio = {};
      this.toggleCopyRebalance = false;
      this.notificationError = false;
      this.notificationRebalance = false;
      this.$store.state.copyChildAddWindow = false;
      console.log('close window')
    },



    checkMasterPortfolio(){
      console.log(this.notificationRebalance)
      if (this.notificationRebalance == true) {
        var response_data = {
          "account_id": this.userPlot[this.activePortfolioSelect].account_id,
          "master_code": this.masterCode,
        }

        console.log("Response data: ", typeof(response_data), response_data, localStorage.getItem('token'))


        this.$store.state.loader = true;
        axios.post(`${this.$store.state.base_url}/api/connect-child/get-master-profile`, response_data, {headers: {Authorization: localStorage.getItem('token')}}).then(response => {
          console.log('Profile response:', response.data.data);
          if (response.status === 200) {
            console.log('Master: ', response.data.data)
            if (Object.keys(response.data.data).length > 0) {
              this.masterPortfolio = response.data.data;
              this.toggleCopyRebalance = true;
            } else throw 'NULL portfolio'
          }
        }).catch(error => {
          this.masterCode = '';
          this.masterPortfolio = {};
          this.toggleCopyRebalance = false;
          this.$toast.error("Произошла ошибка - введите корректный мастер аккаунт", {});
          console.log(error)
        })
        this.$store.state.loader = false;
      } else {
        this.notificationError = true;
      }
    },


    addCopyChildAccount(){
      // BORZ2BINANCE232SPOT
      this.$store.state.loader = true;

      var response_data = {
        "type": "connecting",
        "account_id": this.userPlot[this.activePortfolioSelect].account_id,
        "master_code": this.masterCode,
        "end_portfolio": this.masterPortfolio,
      }
      console.log("Response data: ", typeof(response_data), response_data)

      this.changeCopyAccount({vm:this, data:response_data});
      this.close_window();
      this.$store.state.loader = false;
    },

  },
};
</script>



<style scoped>
  .soft-notification {
    padding: 10px 0;
    margin: 20px 0 10px;
  }

  .border-red {
    border: 1px solid red;
    border-radius: 10px;
    padding: 10px;
  }

  .add-copy-child.fixed-plugin {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  padding-left: 15.625rem;
  /*    transform: translateX(-50%);*/
  display: none;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  }

  .add-copy-child.fixed-plugin.show {
  display: flex;
  }

  .add-copy-child.fixed-plugin .card {
  position: relative !important;
  width: 70%;
  height: fit-content;
  max-height: 80%;
  overflow-y: auto !important;
  z-index: 10;
  background: #FFFFFF;
  border-radius: 15px;
  }

  .add-copy-child.fixed-plugin .card {
  position: relative !important;
  width: 70%;
  height: fit-content;
  max-height: 80%;
  overflow-y: auto !important;
  z-index: 10;
  background: #FFFFFF;
  border-radius: 15px;
  }

  .sl--button {
    cursor: pointer;
  }

  .sl--button.dark {
    background: black;
    color: #FFFFFF;
  }

  .sl--button.light {
    background: #FFFFFF;
    color: black;
    border: 1px solid black;
  }

  .soft_avatar--tehter {
    width: 17px;
    height: 17px;
  }

  .time-radio {
    width: 30px;
    height: 30px;
    background: #BFBFBF;
    border-radius: 8px;
    border: 0;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-radio p {
    padding: 0;
    margin: 0;
  }

  .time-radio.checked-radio {
    background: black;
  }


  .text-color--green {
    color: rgb(25,135,84) !important;
  }

  .my-style-rounded{
    border-radius: 50% ;
    border: 10px solid #F2F2F2 !important; 
    aspect-ratio: 1 / 1 !important;
    width:100%; 
  }

  .slide{
      width: 100%;
      height: 4px;
      background: #AED4FB ;
  }
  .slide::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%; 
    background: #000000  !important;
    cursor: pointer;
  }
  .slide::-moz-range-thumb {
    border-radius: 50%;
    background: #000000 !important;
    cursor: pointer;
  }
</style>