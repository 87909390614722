import { createStore } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";
import axios from 'axios';

export default createStore({
  state: {
    base_url: 'https://amplidyne.site',
    // base_url: 'https://testing-domen.ru',
    // base_url: 'https://jstck.ru',
    // base_url: 'http://192.168.8.18:8000',
    // base_url: 'http://172.20.10.3:8000',
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    isTransparent: "",
    isRTL: false,
    color: "",
    user: {},
    isNavFixed: false,
    authenticated: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    telegramDashboard: localStorage.getItem('telegram') == true ? true : false,
    userTransactions: [],
    slSettings: {
      timeoutIntervals: {
        '1h': {
          name: '1h',
          checked: true,
          value: '1Ч',
        },
        '2h': {
          name: '2h',
          checked: false,
          value: '2Ч',
        },
        '4h': {
          name: '4h',
          checked: false,
          value: '4Ч',
        },
        '6h': {
          name: '6h',
          checked: false,
          value: '6Ч',
        },
        '8h': {
          name: '8h',
          checked: false,
          value: '8Ч'
        },
      },
    },
    tsl: {
      portfolio: null,
      apply_to_coins: [],
      activePortfolio: 0,
    },
    rebalancePortfolio: null,
    activePortfolioSelect: 0,
    addTslData: {
      trailingSwitch: false,
      timeoutEscape: false
      // mappingSwitch: false,
      // additionalAttr: false,
      // fallPercent: 0,
      // mapping: 0,
      // addance: 0,
    },
    briefcases: [
      {
        id: 1,
        name: 'Binance',
        key: 'binance',
      },
      // {
      //   id: 2,
      //   name: 'Huobi',
      //   key: 'huobi',
      // }
    ],
    cryptoAssets:[],
    userRequests: [],
    cryptoAddress: {},
    withdraw: false,
    userPlot: null,
    accountProfile: null,
    topUpCrypto: false,
    binanceAddWindow: false,
    huobiAddWindow: false,
    copyChildAddWindow: false,
    tslAddWindow: false,
    rebalance: false,
    tslAddWindowData: null,
    withdraw_curr_bot: 0,
    userBots: [],
    typeAccount:[
      {
        'slug': 'spot',
        'id': 2,
        'name': 'Спотовый',
        'detail_link':''
      },
      // {
      //   'slug': 'futures',
      //   'id': 1,
      //   'name': 'Фьючерсный',
      //   'detail_link':''
      // }
    ],
    navbarFixed:
      "position-sticky blur shadow-blur left-auto top-1 z-index-sticky px-0 mx-4",
    absolute: "position-absolute px-4 mx-0 w-100 z-index-2",
    bootstrap,
    stopLossData: [],
    loader: false,
  },
  mutations: {
    toggleAllClose(state) {
      state.binanceAddWindow = false;
      state.withdraw = false;
      state.showConfig = false;
      state.rebalance = false;
      state.showConfig = false;
      state.huobiAddWindow = false;
      state.copyChildAddWindow = false;
    },
    tooggleAddTSL(state){
      var current_token = null;
      state.tsl.portfolio[state.activePortfolioSelect].tokens.map(token_object => {
        if (token_object.ticket == state.tsl.apply_to_coins[0] && token_object.tslData && Object.keys(token_object.tslData).length > 0) current_token = token_object
      })
      if (current_token != null) {
        state.addTslData.trailingSwitch = true; //current_token.tslData.trailingSL.status;
        state.addTslData.trailingLimit = current_token.tslData.deviation;
        state.addTslData.trailingPercent = current_token.tslData.percentage;
        state.addTslData.isNew = false;
      } else {
        state.addTslData.trailingSwitch = false;
        state.addTslData.trailingPercent = 1;
        state.addTslData.trailingLimit = 1;
        state.addTslData.timeoutEscape = false;
        state.addTslData.isNew = true;
      }

      state.tslAddWindow = !state.tslAddWindow;
      state.withdraw = false
      state.showConfig  = false
      state.rebalance   = false
      state.binanceAddWindow  = false
      state.huobiAddWindow = false
      state.copyChildAddWindow = false
    },
    tooggleAddBinance(state){
      state.binanceAddWindow = !state.binanceAddWindow;
      state.withdraw = false
      state.showConfig  = false
      state.showConfig = false
      state.huobiAddWindow = false
      state.copyChildAddWindow = false
      state.rebalance = false
    },
    tooggleAddCopyChild(state){
      state.copyChildAddWindow = !state.copyChildAddWindow;
      state.binanceAddWindow = false;
      state.withdraw = false
      state.showConfig  = false
      state.showConfig = false
      state.huobiAddWindow = false
      state.rebalance = false
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
      state.withdraw = false
      state.topUpCrypto = false
      state.binanceAddWindow  = false
      state.huobiAddWindow = false
      state.copyChildAddWindow = false
      state.rebalance   = false
    },
    
    toggleTopUpCrypto(state) {
      state.topUpCrypto = !state.topUpCrypto;
      state.withdraw = false
      state.showConfig = false
      state.binanceAddWindow  = false
      state.huobiAddWindow = false
      state.copyChildAddWindow = false
      state.rebalance = false
    },
    toggleRebalance(state) {
      state.rebalance = !state.rebalance;
      state.withdraw = false
      state.topUpCrypto = false
      state.showConfig  = false
      state.binanceAddWindow  = false
      state.huobiAddWindow = false
      state.copyChildAddWindow = false
    },
    toggleWithdraw(state,id_bot) {
      state.withdraw_curr_bot = id_bot
      state.showConfig = false
      state.topUpCrypto = false
      state.withdraw = !state.withdraw;
      state.binanceAddWindow  = false
      state.huobiAddWindow = false
      state.copyChildAddWindow = false
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");
      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    setTelegramDashboard(state) {
      state.telegramDashboard = !state.telegramDashboard;
      if (state.telegramDashboard == true) {
        localStorage.setItem('telegram', state.telegramDashboard)
      } else{
        localStorage.removeItem('telegram');
      }
    },
    sidebarType(state, payload) {
      state.isTransparent = payload;
    },
    cardBackground(state, payload) {
      state.color = payload;
    },
    cryptoAddress(state, data) {
      state.cryptoAddress = data;
    },
    token(state, token) {
      if (token.length<5)  {
        state.authenticated = false
        state.token  = null
        localStorage.removeItem('token')
      }
      else{
        localStorage.setItem('token', token)
        state.token = token;
        state.authenticated = true
      }
      
    },
    removeToken(state, token) {
      state.token = token;
      state.authenticated = false
    },
    user(state, user) {
      state.user = user;
      state.authenticated = true
    },
    update_user_accounts(state, acc) {
      state.user.accounts = acc;
    },
    user_plot(state, data) {
      state.userPlot = data;
    },
    rebalance_portfolio(state, data) {
      state.rebalancePortfolio = data;
    },
    tsl_profile (state, data) {
      state.tsl.portfolio = data;
    },
    userTransactions(state,tt){
      state.userTransactions = tt
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    toggleEveryDisplay(state) {
      state.showNavbar = !state.showNavbar;
      state.showSidenav = !state.showSidenav;
      state.showFooter = !state.showFooter;
    },
    
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    auth_by_query({commit}, {vm, token}){
      commit("token", token);
      vm.$toast.success("Выполняем вход", { });
    },
    change_password({ state }, {vm, data}) {
      axios.post(`${state.base_url}/api/auth/change-password`, data)
      .then(response => {
        if (response.status === 200) {
          vm.$toast.success("Пароль успешно изменен", { });
          vm.$toast.warning("Пожалуйста проверьте папку СПАМ", { });
          vm.$toast.warning("В целях безопасности выводы будут недоступны сегодня", { });
          vm.loading=false
        }
      })
      .catch(error => {
            vm.$toast.error(error.response.data.message, {
              // optional options Object
            });
          vm.loading=false
      })
    },
    restore({ state }, {vm, data}) {
      axios.post(`${state.base_url}/api/auth/restore`, data)
      .then(response => {
        if (response.status === 200) {
          vm.$toast.success("Новый пароль отправлен на почту", {
            // optional options Object
          });
          vm.$toast.success("Пожалуйста проверьте папку СПАМ", {
            // optional options Object
          });
          if(data.no_redirect){
            console.log('не перенаправляем')
          }
          else{
            vm.$router.push('/sign-in')
          }
        }
      })
      .catch(error => {
            vm.$toast.error(error.response.data.message, {
              // optional options Object
            });
      }) 
    },
    login({ commit, state }, {vm, data}) {
      axios.post(`${state.base_url}/api/auth/check-password`, data)
      .then(response => {
        if (response.status === 200) {
          commit("token", response.data.token);
          vm.$toast.success("Успешный вход", {
            // optional options Object
          });
          if (response.data.count_accounts > 0) location.href = '/invest'
          else location.href = '/exchanges'
        }
      })
      .catch(error => {
        vm.$toast.error(error.response.data.message, {
          // optional options Object
        }); 
      })
    },
    async signup({ commit, state }, {vm, data}) {
      axios.post(`${state.base_url}/api/auth/registration`, data)
      .then(response => {
        if (response.status === 200) {
          commit("token", response.data.token);
          vm.$toast.success("Успешная регистрация", {
            // optional options Object
          });
          location.href = '/exchanges'
        }
      })
      .catch(error => {
        console.log('error.response',error.response)
        vm.$toast.error(error.response.data.message, {
          // optional options Object
        });
      })
    },
    logout({ commit, state }){
      localStorage.removeItem('token');
      commit("removeToken", "");
      state.user = {}
      state.userTransactions = []
      state.userRequests = []
      state.userPlot = null
      state.userBots =[]
    },
    updateUser ({ commit,state }, {vm, data}){
      axios.post(`${state.base_url}/api/auth/edit-profile`, data, {headers: {Authorization: localStorage.getItem('token')}})
      .then(response => {
        if (response.status === 200) {
          commit("user", response.data.data);
          vm.$toast.success("Данные успешно изменены", { });
        }
      })
      .catch(error => {
        console.log('error.response',error.response)
        vm.$toast.error(error.response.data.message, {});
      })
    },
    async AddAccount ({ commit,state }, {vm, data}){
      state.loader = true;
      axios.post(`${state.base_url}/api/add-exchange-account`, data, {headers: {Authorization: localStorage.getItem('token')}})
      .then(response => {
        if (response.status === 200) {
          vm.$toast.success(response.data.message, {});
         commit("update_user_accounts", response.data.data);
          state.binanceAddWindow = false
          state.huobiAddWindow = false
        }
        state.loader = false;
      })
      .catch(error => {
        vm.$toast.error(error.response.data.message, {});
        // vm.$toast.error("Неизвестная ошибка, проверьте данные и сообщите в чат код ошибки номер 555", {});
        state.loader = false;
      })
    },
    async deleteAccount({ commit, state }, {vm, data}) {
      axios.post(`${state.base_url}/api/delete-exchange-account`, data, {headers: {Authorization: localStorage.getItem('token')}})
      .then(response => {
        if (response.status === 200) {
          vm.$toast.success("Удаляем аккаунт", {
            // optional options Object
          });
          commit("delete_user_account", response.data.data);
          state.binanceAddWindow = false
          state.huobiAddWindow = false
          location.reload();
        }
      })
      .catch(error => {
        console.log(error)
        vm.$toast.error("Неизвестная ошибка, проверьте данные и сообщите в чат код ошибки номер 555", {
          // optional options Object
        });
      })
    },
    getTransactions({ commit, state }) {
      axios.get(`${state.base_url}/api/getTransactionsUser`, {headers: {Authorization: localStorage.getItem('token')}})
      .then(response => {
        // console.log(response.data.data);
        if (response.status === 200) {
          commit("userTransactions", response.data.data);
          commit("userRequests", response.data.request);
          
        }
      })
      .catch(error => {
            console.log(error)
      })
    },
    getUserData({ commit, state }) {
        axios.get(`${state.base_url}/api/auth/user-detail`, {headers: {Authorization: localStorage.getItem('token')}})
        .then(response => {
          if (response.status === 200) {
            commit("user", response.data.data);
          }
        })
        .catch(error => {
            if (error.response.data.token === 'undefined') {
              localStorage.removeItem('token');
              commit("removeToken", "");
              state.user = {}
              state.userTransactions = []
              state.userRequests = []
              state.userPlot = null
              state.userBots =[]
            }
        })
    },
    getUserPlot({ commit, state }) {
      axios.get(`${state.base_url}/api/user-plot`, {headers: {Authorization: localStorage.getItem('token')}})
      .then(response => {
        if (response.status === 200) {
          // console.log('UserPlot: ', response.data.data);
          commit("user_plot", response.data.data);
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    changeCopyAccount({ commit, state }, { vm, data }) {
      axios.post(`${state.base_url}/api/change-copy-account`, data, {headers: {Authorization: localStorage.getItem('token')}})
      .then(response => {
        if (response.status === 200) {
          commit("user_plot", response.data.data);
          vm.$toast.success("Изменения аккаунта применены", {});
        } else {
          console.log(response.data.message)
          vm.$toast.error(response.data.message, {});
        }
      })
      .catch(error => {
        console.log(error)
      })
      // 
    },
    getAccountProfile({ commit, state }) {
      console.log("Try response!")
      axios.get(`${state.base_url}/api/get-profile`, {headers: {Authorization: localStorage.getItem('token')}})
      .then(response => {
        if (response.status === 200) {
          commit("tsl_profile", response.data.data);
        }
      })
      .catch(error => {
        console.log(error)
      })
      // 
    },
    getRebalancePortfolio({ commit, state }) {
      console.log('current portfolio ', state.tsl.portfolio[state.activePortfolioSelect].tokens)
      commit("rebalance_portfolio", state.tsl.portfolio[state.activePortfolioSelect].tokens); 
    },
    setRebalancePortfolio({ commit, state }, { vm, data }) {
      axios.post(`${state.base_url}/api/new-rebalance`, data, {headers: {Authorization: localStorage.getItem('token')}})
      .then(response => {
        if (response.status === 200) {
          commit("tsl_profile", response.data.data);
          vm.$toast.success(response.data.message, {});
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    setStopLoss({ commit, state }, { vm, data }) {
      // console.log("Try set sl", data)
      axios.post(`${state.base_url}/api/set-stoploss`, data, {headers: {Authorization: localStorage.getItem('token')}})
      .then(response => {
        // console.log('StopLoss response:', response.data.data);
        if (response.status === 200) {
          commit("tsl_profile", response.data.data);
          vm.$toast.success(response.data.message, {});
        }
      })
      .catch(error => {
        console.log(error)
      })
    },
    getBeforeStopLossData({ state }) {
      var response_data = {};
      response_data[state.tsl.portfolio[state.activePortfolioSelect].id] = {};
      state.tsl.portfolio[state.activePortfolioSelect].tokens.map(token => {
        if (token.tslData != null) {
          response_data[state.tsl.portfolio[state.activePortfolioSelect].id][token.ticket] = token.tslData;
        }
      })
      return response_data
    },
    closeStopLossOrders({ state }, { vm, force = false }) {
      let stopLossOrders = state.tsl.portfolio[state.activePortfolioSelect].tokens.map((token) => {
        if (token.tslData && token.tslData.id) {
          if (force || token.tslData.destroy) {
            return token.tslData.id;
          }
        }
      });

      const payload = {
        account_id: state.tsl.portfolio[state.activePortfolioSelect].id,
        stop_loss: stopLossOrders,
      };

      const context = {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      };

      axios.post(`${state.base_url}/api/stop/remove`, payload, context).then((response) => {
        if (response.status >= 200 && response.status < 400) {
          response.data.stop_loss.forEach((stopLossId) => {
            state.tsl.portfolio[state.activePortfolioSelect].tokens.forEach((token) => {
              if (token.tslData && token.tslData.id === stopLossId) {
                token.tslData = {};
              }
            });
          });

          vm.$toast.success(response.data.message, {});
        }
      }).catch((error) => {
        vm.$toast.error(error.response.data.message, {});
      });
    },
  },
  getters: {
    authenticated: state => {
      return state.authenticated
      // if (localStorage.getItem('token')){
      //   if (localStorage.getItem('token').length > 5) return true
      // }
      // if (state)
      //   return false
    },
    user: state => {
      return state.user
    },
    tsl: state => {
      return state.tsl
    },
    activePortfolioSelect: state => {
      return state.activePortfolioSelect
    },
    addTslData: state => {
      return state.addTslData
    },
    timeframes: state => {
      return state.slSettings.timeframes
    },
    trailingTimeframes: state => {
      return state.slSettings.trailingTimeframes
    },
    timeoutIntervals: state => {
      return state.slSettings.timeoutIntervals
    },
    userRequests: state => {
      return state.userRequests
    },
    userTransactions: state => {
      return state.userTransactions
    },
    typeAccount: state => {
      return state.typeAccount
    },
    userPlot: state => {
      return state.userPlot
    },
    accountProfile: state => {
      return state.accountProfile
    },
    
  },
});
