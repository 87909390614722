<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col">
        <projects-card />
      </div>
    </div>
  </div>
</template>

<script>
import ProjectsCard from "./components/ProjectsCard.vue";

export default {
  name: "invest",
  components: {
    ProjectsCard,
  },
};
</script>
