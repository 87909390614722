<template>
  <aside
     style="padding-top:20px; overflow-x: hidden !important;"
    class="overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs "
    id="sidenav-main"
  >
    <div class="row justify-content-end" v-if='mobile'>
      <div class="col-3">
        <button type="button" class="btn-close" @click='toggleSidebar' aria-label="Close"></button>
      </div>
  </div>

    <div class="sidenav-header">
      <i
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
        id="iconSidenav"
      ></i>
      <a class="m-0 navbar-brand justify-content-end" href="/">
        <img :src="logo" class="navbar-brand-img mb-4 h-100" alt="main_logo" />
        <span class="p-2  font-weight-bold text-light" style="font-size:25px;">Amplidyne</span>
      </a>
      
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";
import { mapMutations } from "vuex";
import logo from "@/assets/img/logologo2.png";

export default {
  name: "index",
  components: {
    SidenavList,
  },
  data() {
    return {
      logo,
    };
  },
  methods: {
    ...mapMutations(["navbarMinimize"]),
    toggleSidebar() {
      this.navbarMinimize();
    },
  },

  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  computed: {
    mobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
    
  },
};
</script>
