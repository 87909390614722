<template>
  <div class="container-fluid mt-4 ">
    
    <div class="row mt-3">
      <h5 class="font-weight-bolder m-2 mb-4">Биржи</h5>
      <div class="col-lg-6 mb-2 w-100">
        <div class="card-transparent h-100">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            style="background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHRMvUoG0EhPCCVJqGwpXMk_D2beeOlOaVfQ&usqp=CAU');"
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <p class="mb-1 pt-2 muted text-success">
                    <i
                      class="fa fa-shield me-2"
                      aria-hidden="true"
                    ></i>Партнер</p>
                <h5 class="text-white font-weight-bolder mb-4 pt-2">Binance</h5>
                <p class="text-white mb-4">
                  Подключить биржу <strong>Binance</strong> для безопасной торговли через портал Amplidyne, доступна Спотовая торговля
                </p>
                <a class="text-light font-weight-bold ps-1 mb-0 icon-move-left mt-auto" style="cursor:pointer" @click="connect_binance()" >Подключить
                  <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 mb-2 ">
        <div class="card-transparent h-100">
          <div
            class="overflow-hidden position-relative border-radius-lg bg-cover h-100"
            style="
              background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSArI5qfSg2xuINRrq-pzv1pnbjCJfZh5bmZw&usqp=CAU');
            "
          >
            <span class="mask bg-gradient-dark"></span>
            <div class="card-body position-relative z-index-1 p-3 h-100">
              <div class="d-flex flex-column h-100">
                <p class="mb-1 pt-2 muted text-success">
                    <i
                      class="fa fa-shield me-2"
                      aria-hidden="true"
                    ></i>
                    Партнер</p>
                <h5 class="text-white font-weight-bolder mb-4 pt-2">
                  Huobi
                </h5>
                <p class="text-white mb-4">
                  Подключить биржу <strong>Huobi </strong>  для безопасной торговли через портал An Invest, доступны: Спотовая 
                </p>
                <a
                  class="text-light font-weight-bold ps-1 mb-0 icon-move-left mt-auto"
                  style="cursor:pointer"
                  @click="connect_huobi()"
                >
                  Подключить
                  <i
                    class="fas fa-arrow-right text-sm ms-1"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>

    <div class="d-flex justify-content-center align-items-center">
      <div class="card d-flex justify-content-center align-items-center w-50 p-3 mt-3">
        <h6 class="font-weight-bold">Мы не берем ваши средства, мы лишь помогаем управлять ими!</h6>
      </div>
    </div>

    <div class="card mb-4 mt-4">
      <div class="card-header pb-0">
        <h6>Подключенные биржи</h6>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">Название</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center">API KEY</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder text-center opacity-7 ps-2 text-center">API SECRET</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center">Добавлено</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center">Статус</th>
                <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 text-center">Действия</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="acc in user.accounts" v-bind:key="acc.id">
                <td>
                  <div class="d-flex justify-content-center px-2 text-center">
                    <div class="my-auto text-center">
                      <h6 class="mb-0 text-sm text-center">{{acc.name}}</h6>
                      <p class="small text-center">
                        {{acc.slug}}/{{acc.type_trade}}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p class="text-sm font-weight-bold small mb-0 text-center">****{{acc.api_key}}</p>
                </td>
                <td class="align-middle small text-center">
                  ****{{acc.secret_key}}
                </td>
                <td  class="small text-center">
                  {{acc.created}}
                </td>
                <td class="small text-center">{{acc.status}}</td>

                <td>
                  <div class="d-flex justify-content-center px-2 text-center">
                    <!-- <span class="text-sm font-weight-bold text-center" style="cursor:pointer; z-index:10" @click="deleting(acc.id)">Удалить</span>  -->
                    <soft-avatar :img="updateLogo" class="soft_avatar--exchange" alt="xd" @click="updating()" />
                    <soft-avatar :img="deleteLogo" class="soft_avatar--exchange" alt="xd" @click="deleting(acc.id)" />
                  </div>
                </td>

                <!-- <td>
                  <span class="text-sm font-weight-bold text-center" style="cursor:pointer; z-index:10" @click="deleting(acc.id)">Удалить</span> 
                  <soft-avatar :img="deleteLogo" class="ms-2 d-flex align-items-center soft_avatar--tehter" alt="xd" />
                </td> -->

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import updateLogo from "@/assets/img/update-logo.svg";
import deleteLogo from "@/assets/img/delete-logo.svg";
import { mapMutations,mapGetters,mapActions  } from "vuex";

export default {
  name: "Exchanges",
  data() {
    return {
      updateLogo,
      deleteLogo
    };
  },
  components: {
    SoftAvatar
  },
  computed:{
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["getUserData", "deleteAccount"]),
    ...mapMutations(["tooggleAddHuobi", "tooggleAddBinance"]),
    connect_binance(){
      this.tooggleAddBinance()
    },
    connect_huobi(){
      this.tooggleAddHuobi()
    },
    deleting(id){
      var data = {
        account_id: id,
      }
      this.deleteAccount({vm:this,data:data})
    },
    updating() {
      this.getUserData();
    }
  },
};
</script>

<style scoped>
  .bg-dark-outlined{
    border: 1px solid black;
    color:black
  }

  .soft_avatar--exchange {
    width: 30px;
    margin: 0 8px;
    cursor: pointer;
  }
</style>