<template>
  <div class="card-body px-0 pb-2">
    <div class="table-responsive mt-3">
      <div class="col-lg-6 col-7 ps-4">
        <h6 class="font-weight-bold">Подключённые пользователи</h6>
      </div>
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-3">Имя пользователя</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Дата подключения</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Биржа</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Объём управления</th>
            <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="current_user in usersData" v-bind:key="current_user.id">
            <td class="align-middle text-center text-sm">
              <span class="text-m font-weight-bold">{{ current_user.name }}</span>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="text-m font-weight-bold">{{ current_user.created_date }}</span>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="text-m font-weight-bold">{{ current_user.slug }}</span>
            </td>
            <td class="align-middle text-center text-sm">
              <span class="text-m font-weight-bold">{{ new Intl.NumberFormat('en-US').format(current_user.use_balance) }}</span>
            </td>
            <td class="align-middle d-flex flex-row justify-content-center">
              <!-- <button style="cursor:pointer"  @click="changeCopyChild(current_user.account_id, 'stopping')" class="badge rounded-pill bg-warning border-0" v-if="current_user.status == 'Active'">Остановить</button> -->
              <!-- <button style="cursor:pointer"  @click="changeCopyChild(current_user.account_id, 'starting')" class="badge rounded-pill bg-success border-0 b-none" v-else-if="current_user.status == 'Stopped'">Запустить</button> -->
              <button style="cursor:pointer"  @click="changeCopyChild(current_user.account_id, 'deleting')" class="badge rounded-pill bg-danger border-0 mx-3">Отключить</button>
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<style scoped></style>

<script>
import { mapActions } from "vuex";
import img1 from "../../assets/img/small-logos/logo-xd.svg";

export default {
  name: "userss-card",
  props: [
    'usersData',
    'mastercopyId'
  ],
  data() {
    return {
      img1,
    };
  },
  methods: {
    ...mapActions(["changeCopyAccount"]),
    changeCopyChild(account_id, type){
      var response_data = {
        "type": type,
        "account_id": account_id,
      }
      console.log("Response data: ", typeof(response_data), response_data)
      this.changeCopyAccount({vm:this, data:response_data});
    },
  }
};
</script>

<style scoped>
  .bg-success {
    background: #26A17B !important;
  }
</style>
