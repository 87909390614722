<template>
  <div class="fixed-plugin " >
  
    <div class="shadow-lg card blur d-flex flex-column" style="overflow-y: scroll; overflow-x: hidden !important; ">
      <div class="pt-3 pb-0 bg-transparent card-header">
        <div class="mt-3 float-end "  >
          <close @click="close_window"/>
        </div>
      </div>

      <div class="p-3 pt-4 mt-3" style="background: #F8F9FA; border-radius: 12px;;">
        <h5>Трейлинг Стоп Лосс</h5>
        <!-- <div class="mt-1 d-flex flex-column">
          <p class="text-sm p-0">Таймфрейм</p>
          <div class="row d-flex justify-content-between mx-1">
            <button
              v-for="time in timeframes"
              :key="time"
              :value="time.name"
              v-bind:class="(time.checked == true)?'checked-radio':''"
              class="text-sm time-radio"
              @click="timeframeChange(timeframes, $event.target.value)">{{ time.value }}</button>
          </div>
          <p class="mb-0 mt-1 text-sm fw-light text-sm-start">Выбранный таймфрейм: 1 Ч</p>
        </div> -->

        <hr class="horizontal dark my-sm-4" />

        <div class="pt-0 w-100 d-flex flex-column">
          <div class="d-flex align-items-center justify-content-center w-100">
            <div class="w-100 d-flex flex-row justify-content-start">
              <soft-switch id="trailingSwitchElement" class="pt-1" name="trailingSwitchElement" :checked="addTslData.trailingSwitch" v-on:change="addTslData.trailingSwitch=!addTslData.trailingSwitch"></soft-switch>
              <p class="text-sm pt-1">Трейлинг Стоп-Лосс</p>
            </div>
            <soft-avatar :img="img1" size="sm" class="me-3 d-flex align-items-start" alt="xd" />
          </div>
          <div class="row d-flex justify-content-between pt-0 my-2" v-if="addTslData.trailingSwitch">
            <label>Процент тейк-профита от изначальной цены</label>
            <div class="d-flex align-items-center justify-content-center w-100">
              <div class="d-flex align-items-center justify-content-center w-100">
                <div class="text-muted">
                  <span class="badge rounded-pill bg-dark d-flex align-items-center justify-content-center" style="width: 40px;">{{ addTslData.trailingLimit }}</span>
                </div>
                <div class="me-3 d-flex align-items-center w-100"> 
                  <input type="range" :min="1" :max="100" v-model="addTslData.trailingLimit" class="form-range slide bg-none" id="trailingLimitRange">
                </div>
              </div>
            </div>
            <p class="mb-0 mt-1 text-sm fw-light text-sm-start">Это будет ваш первоначальный уровень, который будет срабатывать при движении цены в вашу пользу. Например, вы можете установить его на 5% ниже <b>текущей цены</b>.</p>
          </div>
          <div class="pt-0 my-2 w-100" v-if="addTslData.trailingSwitch">
            <label>Процент отклонения</label>
            <div class="d-flex align-items-center justify-content-center w-100">
              <div class="text-muted">
                <span class="badge rounded-pill bg-dark d-flex align-items-center justify-content-center" style="width: 40px;">{{ addTslData.trailingPercent }}</span>
              </div>
              <div class="me-3 d-flex align-items-center w-100"> 
                <input type="range" :min="1" :max="100" v-model="addTslData.trailingPercent" class="form-range slide bg-none" id="balancepercentRange">
              </div>
            </div>
            <p class="mb-0 mt-1 text-sm fw-light text-sm-start">Настройте трейлинг стоп-лосс, чтобы он автоматически сдвигался по мере движения цены. Например, при достижении нового максимума цены на 2%, трейлинг стоп-лосс смещается на 2% ниже этого нового максимума.</p>
          </div>
        </div>

        <hr class="horizontal dark my-sm-4" />

      </div>
      
      <a v-if="addTslData.isNew" class="mt-3 btn bg-gradient-dark w-100" @click="addTSL()">Установить</a>
      <a v-else class="mt-3 btn bg-danger w-100 text-white" @click="deleteTSL()">Удалить</a>

    </div>
  </div>
</template>

<script>
import SoftSwitch from "@/components/SoftSwitch.vue";
import SoftAvatar from "@/components/SoftAvatar.vue";
import img1 from "../assets/img/small-logos/logo-xd.svg";
import Close from "../components/Icon/Close.vue";

// import SoftInput from "@/components/SoftInput.vue";
import { mapActions, mapGetters, mapState } from "vuex";

import axios from 'axios';

export default {
  name: "addTsl",
  props: ["toggle"],
  data() {
    return {
      img1,
    };
  },
  components: {
    Close,
    SoftSwitch,
    SoftAvatar,
  },
  computed: {
    ...mapGetters(["timeoutIntervals", "tsl", "addTslData", "activePortfolioSelect"]),
    ...mapState(['base_url']),
  },
  methods: {
    ...mapActions(["setStopLoss", "closeStopLossOrders"]),
    close_window(){
      console.log('close window')
      this.$store.state.tslAddWindow = false;
      this.tsl.apply_to_coins = [];
    },
    getBeforeStopLossData() {
      var response_data = {};
      response_data[this.tsl.portfolio[this.activePortfolioSelect].id] = {};
      this.tsl.portfolio[this.activePortfolioSelect].tokens.map(token => {
        if (token.tslData != null) {
          response_data[this.tsl.portfolio[this.activePortfolioSelect].id][token.ticket] = token.tslData;
        }
      })
      return response_data
    },
    addTSL(){
      const payload = {
        account_id: this.tsl.portfolio[this.activePortfolioSelect].id,
      };
      
      payload.stop_loss = this.tsl.apply_to_coins.map((coin) => ({
        symbol: coin,
        percentage: this.addTslData.trailingPercent,
        deviation: this.addTslData.trailingLimit,
      }));

      const context = {
        headers: {
          Authorization: localStorage.getItem('token'),
        }
      };

      axios.post(`${this.base_url}/api/stop/create`, payload, context).then((response) => {
        if (response.status >= 200 && response.status < 400) {
          response.data.stop_loss.forEach((stop_loss) => {
            this.tsl.portfolio[this.activePortfolioSelect].tokens.forEach((token) => {
              if (token.ticket === stop_loss.symbol) {
                token.tslData = stop_loss;
              }
            });
          });

          this.$toast.success(response.data.message, {});
        }
      }).catch((error) => {
        this.$toast.error(error.response.data.message, {});
      });
 
      // this.setStopLoss({vm:this,data:response_data});
      this.close_window();
    },
    deleteTSL() {
      this.tsl.portfolio[this.activePortfolioSelect].tokens.forEach((token) => {
        this.tsl.apply_to_coins.forEach((applyCoin) => {
          if (token.ticket === applyCoin && token.tslData && token.tslData.id) {
            token.tslData.destroy = true;
          }
        });
      });

      this.closeStopLossOrders({ vm: this, force: false });
      this.close_window();
    },
    getTimeframe(parent) {
     for (var timeframe in parent) {
        if (parent[timeframe].checked == true) {
          return timeframe
        }
      }
    },
    timeframeChange(parent, value){
      for (var timeframe in parent) {
        parent[timeframe].checked = false;
      }
      parent[value].checked = true;
    },
    changeSwitch() {
      // console.log("ok");
    }
  },
};
</script>


<style scoped>
  .card-body {
    flex: auto !important;
  }

  .time-radio {
    width: 30px;
    height: 30px;
    background: #BFBFBF;
    border-radius: 8px;
    border: 0;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-radio p {
    padding: 0;
    margin: 0;
  }

  .time-radio.checked-radio {
    background: black;
  }

  .slide{
      width: 100%;
      height: 4px;
      background: #AED4FB ;
  }

  .slide::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%; 
    background: #000000  !important;
    cursor: pointer;
  }

  .slide::-moz-range-thumb {
    border-radius: 50%;
    background: #000000 !important;
    cursor: pointer;
  }
</style>