<template>
  <footer class="footer " >
    <div class="container-fluid" style="height:300px !important;">
      <div class="row align-items-center justify-content-lg-between pb-5">
        <div class="mb-4 col-lg-6 mb-lg-0 pt-4">
          <div
            class="text-sm text-center copyright text-muted"
            :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'"
          >
            ©
            {{ new Date().getFullYear() }}
             Все права защищены. Amplidyne
              <!-- <a
                style="cursor:pointer"
                @click="tooggleAddBinance"
                target="_blank"
                class="text-secondary me-xl-4 me-4"
              >
              </a> -->

              
            
          </div>
          <ul
                  class="nav nav-footer justify-content-center justify-content-lg-start mt-2"
                  :class="this.$store.state.isRTL ? 'justify-content-lg-end' : 'justify-content-lg-start'"
                > 
          
            <li class="nav-item">
              <a
                href="https://t.me/"
                target="_blank"
                class="text-secondary me-xl-4 me-4"
              >
                <span class="text-lg fab fa-telegram"></span>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="javascript:;"
                target="_blank"
                class="text-secondary me-xl-4 me-4"
              >
                <span class="text-lg fab fa-vk"></span>
              </a>
            </li>
            <li class="nav-item">
              <a
                href="https://instagram.com"
                target="_blank"
                class="text-secondary me-xl-4 me-4"
              >
                <span class="text-lg fab fa-instagram"></span>
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-6">
          
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  
  name: "app-footer",
  methods: {
    ...mapMutations([
    "tooggleAddBinance"]),

   
  }
};

</script>
