<template>
  <div class="card">
    <div class="card-header pb-0">
      <div class="row">
        <div class="col-lg-6 col-7">
          <h6>Портфели</h6> 
        </div>
      </div>
    </div>
    <div class="card-body px-0 pb-2">
      <div class="d-flex flex-column justify-content-between align-items-start">
        <div class="w-100 d-flex flex-row mx-3" v-if="tsl.portfolio"> <!-- v-if="tsl.portfolio" -->
          <div class="mt-1 d-flex flex-column col-lg-3">
            <span class="ms-1 text-sm">Выберите портфель</span>
            <select class="form-select mb-2 mt-1" v-model="activePortfolio" @change="changeActiveSelect()">
              <option  v-bind:key="key" v-for="(portfolio, key) in tsl.portfolio" :value="key" >
                {{ portfolio.full_name }}
              </option>
            </select>
          </div>

          <!-- <div class="mt-1 d-flex flex-column ms-6">
            <span class="ms-1 text-sm p-0 mb-1">Временной промежуток</span>
            <div class="row d-flex flex-row justify-content-between mx-1 mt-1">
              <button
                v-for="time in tsl.portfolio.binance[0].changing"
                :key="time"
                :value="time.name"
                v-bind:class="(time.checked == true) ? 'checked-radio' : ''"
                class="text-sm time-radio"
                @click="timeframeChange(tsl, $event.target.value)">{{ time.value }}</button>
            </div>
          </div> -->
        </div>
        <div class="w-100 d-flex flex-row px-3 mt-1" style="gap: 10px" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].available_now == true">
          <div class="w-25">
            <mini-statistics-card
              title="Баланс"
              :value="`${new Intl.NumberFormat('en-US').format(userPlot[activePortfolio].statistic ? userPlot[activePortfolio].statistic.total_balance : 0)}`"
              :percentage="{
                value: '+505%',
                color: 'text-success',
              }"
              :icon="{
                component: 'ni ni-money-coins',
                background: iconBackground,
              }"
              direction-reverse
            />
          </div>
          <!-- <div class="w-100">
            <mini-statistics-card
              title="Результат за 24 часа"
              :value="`${new Intl.NumberFormat('en-US').format(userPlot ? userPlot[activePortfolio].statistic.day : 0)}`"
              :percentage="{
                value: '₽ +3%',
                color: 'text-success',
              }"
              :icon="{
                component: ' ni ni-world',
                background: iconBackground,
              }"
              direction-reverse
            />
          </div>
          <div class="w-100">
            <mini-statistics-card
              title="Результат за неделю"
              :value="`${new Intl.NumberFormat('en-US').format(userPlot ? userPlot[activePortfolio].statistic.week : 0)}`"
              :percentage="{
                value: '-2%',
                color: 'text-danger',
              }"
              :icon="{
                component: 'ni ni-paper-diploma',
                background: iconBackground,
              }"
              direction-reverse
            />
          </div>
          <div class="w-100">
            <mini-statistics-card
              title="Результат за месяц"
              :value="`${new Intl.NumberFormat('en-US').format(userPlot ? userPlot[activePortfolio].statistic.month: 0)}`"
              :percentage="{
                value: '+5%',
                color: 'text-dark',
              }"
              :icon="{
                component: 'ni ni-money-coins',
                background: 'bg-warning',
              }"
              direction-reverse
            />
          </div> -->
        </div>
        <div class="w-100 d-flex flex-row px-3 mb-2">
          <div class="col-12">
            <div class="card z-index-2">
              <gradient-line-chart
                id="chart-line"
                title="Состояние портфеля"
                v-if='userPlot && user.revenue_today '
                :description='`<i class="fa fa-arrow-up text-success"></i> <span class="font-weight-bold">+${user.revenue_today.toLocaleString("ru")}р </span> за сегодня`'
                :chart="{
                  labels: this.userPlot.labels,
                  datasets: [
                    {
                      label: 'Баланс учетом прибыли',
                      data: [],
                    },
                    {
                      label: 'Депозит',
                      data: [],
                    },
                  ],
                }"
              />
            </div>
          </div>
        </div>

        <div class="w-100 d-flex justify-content-center align-items-center my-3" v-if="tsl && tsl.portfolio && Object.keys(tsl.portfolio).length > 0 &&tsl.portfolio[activePortfolio].available_now == false">
          <div class=" w-60 card d-flex justify-content-center align-items-center w-35 p-3 mb-2 border">
            <h6 class="font-weight-bold">На данный момент аккаунт недоступен</h6>
          </div>
        </div>

        <div class="w-100 d-flex flex-row justify-content-between mt-2 mx-3 mb-2" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading.account_status == 'master' && tsl && tsl.portfolio && Object.keys(tsl.portfolio).length > 0 && tsl.portfolio[activePortfolio].available_now == true">
          <div class="col-3">
            <span v-if="showButtonOpenTslBulk" @click="openTSLBulk()" class="w-100 badge rounded-pill bg-dark font-weight-semibold text-xs" style="cursor:pointer">Установить TSL для выбранных монет</span>
          </div>
          <span @click="rebalanceClickPortfolio()" class="col-3 badge rounded-pill bg-dark font-weight-semibold text-xs mx-6" style="cursor:pointer">Ребалансировка портфеля</span>
          <span @click="forceCloseStopLoss()" class="col-3 badge rounded-pill bg-dark font-weight-semibold text-xs mx-6" style="cursor:pointer">Удалить все TSL</span>
          <!-- {{tsl.apply_to_coins}} -->
        </div>
      </div>
    
      <div class="table-responsive mt-3">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 px-5">Токен</th>
              <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Изменение цены</th> -->
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 col-1">Цена</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Количество</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Баланс</th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Процент</th>
              <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Средняя цена покупки</th> -->
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading.account_status == 'master'  && tsl && tsl.portfolio && Object.keys(tsl.portfolio).length > 0 && tsl.portfolio[activePortfolio].available_now == true">Smart TSL</th>
              <!-- <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Ребалансировка</th> -->
            </tr>
          </thead>
          <tbody v-if="tsl && tsl.portfolio && Object.keys(tsl.portfolio).length > 0">
            <tr v-for="current_token in tsl.portfolio[activePortfolio].tokens.filter(item => (item.amount >= 1 || userPlot[activePortfolio].copytrading.account_status == 'master'))" v-bind:key="current_token.id">
              <td class="align-middle text-center text-sm">
                <div class="d-flex align-items-center ps-4">
                  <div class="px-3 py-1 d-flex flex-column justify-content-center" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading.account_status == 'master' && tsl && tsl.portfolio && Object.keys(tsl.portfolio).length > 0 && tsl.portfolio[activePortfolio].available_now == true">
                    <div class="form-check">
                      <input :disabled="Object.keys(current_token.tslData).length > 0" :name="current_token.ticket" @change="checkeboxChange($event.target.name,$event.target.checked)" class="form-check-input" type="checkbox" value="" id="'tslCheckbox_'+current_token.ticket" :checked="is_checked(current_token)">
                    </div>
                  </div>
                  <!-- <div>
                    <soft-avatar :img="img1" size="sm" class="me-3 d-flex align-items-center" alt="xd" />
                  </div> -->
                  <div class="ps-3 d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm text-uppercase">{{ current_token.ticket }}</h6>
                    <!-- <h6 class="mb-0 text-sm fw-light">{{ current_token.ticket }}</h6> -->
                  </div>
                </div>
              </td>
              <!-- <td class="align-middle text-center text-sm">
                <span :class="['text-xs', 'font-weight-bold', current_token.changing > 0 ? 'text-color--green' : 'text-danger', ]">{{ current_token.changing[activeTimeframe] >= 0 ? "+" : "" }}{{ current_token.changing[activeTimeframe].change }} %</span>
              </td> -->
              <td class="align-middle text-center text-sm">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="text-xs font-weight-bold">{{ new Intl.NumberFormat('en-US').format(current_token.currentPrice) }}</span>
                  <soft-avatar :img="tetherImg" class="ms-2 d-flex align-items-center soft_avatar--tehter" alt="xd" />
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <span class="text-xs font-weight-bold">{{ new Intl.NumberFormat('en-US', { maximumFractionDigits: 14 }).format(current_token.balance) }} {{ current_token.ticket }}</span>
                <!-- <span class="text-xs font-weight-bold">{{ current_token.balance.toString().split('.')[1].length > 5 ? current_token.balance : new Intl.NumberFormat('en-US').format(current_token.balance) }}</span> -->
              </td>
              <td class="align-middle text-center text-sm">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="text-xs font-weight-bold">{{ new Intl.NumberFormat('en-US').format(current_token.amount) }}</span>
                  <soft-avatar :img="tetherImg" class="ms-2 d-flex align-items-center soft_avatar--tehter" alt="xd" />
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                <div class="d-flex align-items-center justify-content-center">
                  <span class="text-xs font-weight-bold">{{ new Intl.NumberFormat('en-US').format(current_token.percent) }} %</span>
                  <!-- <soft-avatar :img="tetherImg" class="ms-2 d-flex align-items-center soft_avatar--tehter" alt="xd" /> -->
                </div>
              </td>
              <td class="align-middle text-center text-sm col-2" v-if="userPlot && Object.keys(userPlot).length > 0 && userPlot[activePortfolio].copytrading.account_status == 'master' && tsl && tsl.portfolio && Object.keys(tsl.portfolio).length > 0 && tsl.portfolio[activePortfolio].available_now == true">
                <div class="d-flex align-items-center justify-content-center">
                  <span @click="openTSL(current_token.ticket)" class="badge rounded-pill sl--button" :class="[Object.keys(current_token.tslData).length > 0 ? 'dark' : 'light']" >TSL</span>
                </div>
              </td>
              <!-- <td class="align-middle">
                <div class="d-flex align-items-center justify-content-center">
                  <div class="me-3 d-flex align-items-center"> 
                    <input type="range" :min="0" :max="accountBalance"  v-model=current_token.balance class="form-range slide" id="`customRange${current_token.id}`">
                  </div>
                  <div min="0" max="365"  style="width: 150px !important; display:flex; justify-content: space-between;">
                    <div class="text-muted " style="width: 100% !important;">
                      <span class="badge rounded-pill bg-dark" style="width: 100% !important;">{{ roundNumber(current_token.balance * current_token.currentPrice, current_token.digitsAfter) }} $ : {{ roundNumber(((accountBalance - current_token.balance * current_token.currentPrice)/100), current_token.digitsAfter) }} %</span>
                    </div>
                  </div>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>


<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import MiniStatisticsCard from "@/examples/Cards/MiniStatisticsCard.vue";
import GradientLineChart from "@/examples/Charts/GradientLineChart.vue";
// import SoftCheckbox from "@/components/SoftCheckbox.vue";
import { mapMutations, mapActions, mapGetters, mapState } from "vuex";
import img1 from "../../assets/img/small-logos/logo-xd.svg";
import tetherImg from "../../assets/img/tether-usdt-logo.svg";
import {
  faHandPointer,
  faUsers,
  faCreditCard,
  faScrewdriverWrench,
} from "@fortawesome/free-solid-svg-icons";

import axios from 'axios';

export default {
  name: "projects-card",
  data() {
    return {
      iconBackground: "bg-gradient-info",
      faCreditCard,
      faScrewdriverWrench,
      faUsers,
      faHandPointer,
      img1,
      tetherImg,
      // accountBalance: 25000,
      activeTimeframe: '1h',
      activePortfolio: 0,
    };
  },
  components: {
    SoftAvatar,
    MiniStatisticsCard,
    GradientLineChart,
  },
  computed: {
    ...mapGetters([ ]),//, "userPlot"
    ...mapState(['base_url', 'tsl',"userPlot", "activePortfolioSelect", "user", 'timeoutIntervals', 'addTslData']),
    showButtonOpenTslBulk() {
      if (this.tsl.apply_to_coins.length>0) {
        return true
      }
      return false
    },
    filteredTokens() {
      return this.items.filter(item => item.category === this.filterBy)
    }
  },
  created() {
    this.getAccountProfile();
    this.getUserPlot();
    this.activePortfolio = this.activePortfolioSelect;

    setTimeout(() => {
      this.reloadStopLosses();
    }, 1500);

    console.log("reinvest", this.$store.state.rebalance)
    // this.toggleRebalance()
  },
  watch: {
    // tsl: {
    //   handler () {
    //     this.reloadStopLosses();
    //   },
    //   deep: true,
    // },
  },
  methods: {
    ...mapActions(["getAccountProfile", "getRebalancePortfolio", "setStopLoss", "getBeforeStopLossData", "getUserPlot", "closeStopLossOrders"]),
    ...mapMutations(["tooggleAddTSL", "toggleRebalance"]),
    forceCloseStopLoss() {
      this.closeStopLossOrders({ vm: this, force: true });
    },
    reloadStopLosses () {
      const data = {
        account_id: this.tsl.portfolio[this.activePortfolio].id,
      };

      const context = {
        headers: {
          Authorization: localStorage.getItem('token'),
        }
      };

      axios.post(`${this.base_url}/api/stop/get`, data, context).then((response) => {
        if (response.status >= 200 && response.status < 400) {
          response.data.stop_loss.forEach((stop_loss) => {
            this.tsl.portfolio[this.activePortfolio].tokens.forEach((token) => {
              if (token.ticket === stop_loss.symbol.split('/')[0]) {
                token.tslData = stop_loss;
              }
            });
          });
        }
      });
    },
    rebalanceClickPortfolio() {
      this.getRebalancePortfolio()
      this.toggleRebalance();
    },
    changeActiveSelect() {
      this.$store.state.activePortfolioSelect = this.activePortfolio;
      this.tsl.apply_to_coins = [];
      this.$store.state.tslAddWindow = false
    },
    getTokenPrice(balance, price) {
      console.log(balance, price)
      return Number(balance * price)
    },
    roundNumber(number, digits) {
      var multiple = Math.pow(10, digits);
      var rndedNum = Math.round(number * multiple) / multiple;
      return rndedNum;
    },
    openTSL(element){
      this.tsl.apply_to_coins = [element]
      if (!this.$store.state.tslAddWindow){
        this.tooggleAddTSL();
      }

    },
    openTSLBulk() {
      this.tooggleAddTSL();
    },
    is_checked(ticket){
      // console.log("is checked tsl: ", this.tsl)
      if (Object.keys(ticket.tslData).length == 0) {
        return this.tsl.apply_to_coins.includes(ticket.ticket);
      } else {
        return false;
      }
      
    },
    checkeboxChange(value,checked){
      // console.log(value,checked)
      if (checked){
        // Для того, чтобы у кого уже есть Sl снималась галочка. Код рабочий, но нет обновления.
        // this.tsl.apply_to_coins.map(coin => {
        //   if (this.findTokenSL(coin) != false) {
        //     console.log("find already sl token: ", coin)
        //     const index = this.tsl.apply_to_coins.indexOf(coin);
        //     if (index > -1) { // only splice array when item is found
        //       this.tsl.apply_to_coins.splice(index, 1); // 2nd parameter means remove one item only
        //       console.log('deleted already sl token', index)
        //       this.$forceUpdate();
        //       console.log(this.tsl.apply_to_coins)
        //     }
        //   }
        // })
        this.tsl.apply_to_coins.push(value);
      }
      else{
        const index = this.tsl.apply_to_coins.indexOf(value);
        if (index > -1) { // only splice array when item is found
          this.tsl.apply_to_coins.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    },
    timeframeChange(parent, value) {
      console.log(parent)
      for (var timeframe in parent.portfolio.changing) {
        console.log(timeframe)
        parent.portfolio.changing[timeframe].checked = false;
      }
      parent.portfolio.changing[value].checked = true;
    }, 
    deleteAllTSL() {
      var response_data = this.getBeforeStopLossData();

      response_data[this.tsl.portfolio[this.activePortfolio].id] = {};

      this.setStopLoss({vm: this, data: response_data});
    }
  },
};
</script>




<style scoped>

  .sl--button {
    cursor: pointer;
  }

  .sl--button.dark {
    background: black;
    color: #FFFFFF;
  }

  .sl--button.light {
    background: #FFFFFF;
    color: black;
    border: 1px solid black;
  }

  .soft_avatar--tehter {
    width: 17px;
    height: 17px;
  }

  .time-radio {
    width: 30px;
    height: 30px;
    background: #BFBFBF;
    border-radius: 8px;
    border: 0;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .time-radio p {
    padding: 0;
    margin: 0;
  }

  .time-radio.checked-radio {
    background: black;
  }


  .text-color--green {
    color: rgb(25,135,84) !important;
  }

  .my-style-rounded{
    border-radius: 50% ;
    border: 10px solid #F2F2F2 !important; 
    aspect-ratio: 1 / 1 !important;
    width:100%; 
  }

  .btn-calc{
    background: rgba(0, 0, 0, 0.1)  !important;
    border-radius: 5px;
    font-weight: 400;
    color: #2D2D2D  !important;
    box-shadow: none  !important;
  }

  .btn-calc-active{
    background-color: #AED4FB !important;
    color: #2D2D2D;
    border-radius: 5px;
    font-weight: 400;
    box-shadow: none  !important; 
  }
  .slide{
      width: 100%;
      height: 4px;
      background: #AED4FB ;
  }
  .slide::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    border-radius: 50%; 
    background: #000000  !important;
    cursor: pointer;
  }

  .slide::-moz-range-thumb {
    border-radius: 50%;
    background: #000000 !important;
    cursor: pointer;
  }
</style>