<template>
  <div class="mb-3 card">
    <div class="p-3 card-body">
      <div class="d-flex" :class="directionReverse ? reverseDirection : ''">
        <div>
          <div class="text-center shadow icon icon-shape border-radius-md" :class="typeof icon === 'object' ? icon.background : ''">
            <i
              class="text-lg opacity-10"
              :class="typeof icon === 'string' ? icon : icon.component"
              aria-hidden="true"
            ></i>
          </div>
        </div>
        <div :class="classContent">
          <div class="numbers">
            <p class="mb-0 text-sm font-weight-bold" :class="title.color" >
              {{ typeof title === "string" ? title : title.text }}
            </p>
            <div class="d-flex flex-row align-items-center">
              <soft-avatar :img="tetherImg" class="me-1 d-flex align-items-center soft_avatar--tehter" alt="xd" />
              <h5 class="ms-2 mb-0 font-weight-bolder" :class="value.color">
                {{
                  typeof value === "string" || typeof value === "number"
                    ? value
                    : value.text
                }}
                <span
                  class="text-sm font-weight-bolder"
                  :class="percentage.color"
                >
                  {{
                    typeof percentage === "number" ||
                    typeof percentage === "string"
                      ? `${percentage}`
                      : ""
                  }}

                  {{
                    percentage && percentage === "object"
                      ? `${percentage.value}`
                      : ""
                  }}
                </span>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import tetherImg from "../../assets/img/tether-usdt-logo.svg";
export default {
  name: "MiniStatisticsCard",
  props: {
    directionReverse: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [Object, String],
      default: null,
      text: {
        type: String,
      },
      color: {
        type: String,
      },
    },
    value: {
      type: [Object, String, Number],
      required: true,
      text: {
        type: [String, Number],
      },
      color: {
        type: String,
      },
    },
    percentage: {
      type: [Object, String],
      value: {
        type: String,
      },
      color: {
        type: String,
      },
      default: () => ({
        color: "text-success",
      }),
    },
    icon: {
      type: [String, Object],
      component: {
        type: String,
      },
      background: {
        type: String,
      },
      default: () => ({
        background: "bg-white",
      }),
    },
    classContent: {
      type: String,
      default: "",
    },
  },
  components: {
    SoftAvatar,
  },
  data() {
    return {
      reverseDirection: "flex-row-reverse justify-content-between",
      tetherImg
    };
  },
};
</script>


<style scoped >
  
  .soft_avatar--tehter {
    width: 20px;
    height: 20px;
    margin-top: 2px;
  }

</style>